.header_sublist {
	position: absolute;
	left: 0;
	z-index: 5;

	width: 100%;
	overflow-x: hidden;

	max-height: calc(100vh - var(--height-header));

	cursor: initial;

	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}

	@include bp($point_2, min) {
		top: 100%;
		height: auto;

		padding: 2.8rem var(--gl-indent) 5.2rem;

		transform: translateY(-2rem);
		opacity: 0;
		visibility: hidden;
		overflow-y: auto;

		background-color: $white;
		box-shadow: 0 1.4rem 1.4rem rgba($black, 0.2);

		transition: transform 0.3s, opacity 0.4s;

		.header_menu__item_wrap:hover &,
		.header_menu__item_wrap:focus & {
			transform: translateY(0);
			opacity: 1;
			visibility: visible;
		}
	}

	@include bp($point_3) {
		top: 0;
		height: 100%;

		transform: translateX(-100%);

		transition: transform 0.3s ease;

		.header_mobile__item--state & {
			transform: translateX(0);
		}
	}
}

.header_sublist__body {
	@include bp($point_3) {
		padding: 2.6rem 2.8rem 5rem 2.8rem;
		background-color: $beige_1;
	}
}

.header_sublist__btn {
	position: relative;

	padding-left: 1.2rem;

	font-size: 1.6rem;
	letter-spacing: -0.01em;
	color: $gold_2;

	&:not(:last-child) {
		margin-bottom: 2.8rem;
	}
}

.header_sublist__btn_icon {
	position: absolute;
	top: 0.5rem;
	left: 0;

	width: 0.8rem;
	height: 1rem;

	transform: rotate(-180deg);
}

.header_sublist__in {
	// padding-top: 2.8rem;
}

.header_sublist__wrap {
	@include flex_block(row, wrap, flex-start, flex-start);
	margin: 0 -8rem -3rem 0;
}

.header_sublist__column {
	flex-shrink: 0;
	width: 33.333%;

	padding: 0 8rem 3rem 0;
}

.header_sublist__title {
	position: relative;
	padding-bottom: 1rem;

	font-size: 1.8rem;
	font-weight: 500;
	color: $gray_2;

	line-height: 1.33;
	letter-spacing: 0.11em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;

		content: "";

		width: 100%;
		height: 1px;

		background-color: $white;
	}
}

.header_sublist__heading {
	position: relative;
	padding-bottom: 1rem;

	font-size: 1.8rem;
	font-weight: 500;
	color: $gray_2;

	line-height: 1.33;
	letter-spacing: 0.11em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 1.6rem;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;

		content: "";

		width: 100%;
		height: 1px;

		background-color: $beige_2;
	}
}

.header_sublist__items {
}

.header_sublist__item {
	padding: 1rem 1.4rem;
	margin-left: -1.4rem;

	transition: background 0.3s ease;

	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}

	&:hover,
	&:focus {
		background-color: $white;
	}
}

.header_sublist__item_club {
	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}
}

.header_sublist__link {
	@include h4;

	position: relative;
	padding-left: 6rem;

	color: $black;
	line-height: 1.14;
	letter-spacing: -0.01em;

	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;

		content: "";

		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		border: 1px solid $gold_2;
	}

	.header_sublist__items:hover &,
	.header_sublist__items:focus & {
		text-decoration: none;
		font-weight: 500;
	}
}

.header_sublist__link_club {
	font-size: 2.2rem;
	line-height: 1.45;
	color: $black;

	transition: color 0.3s ease;

	&:hover,
	&:focus {
		color: $brown_3;
		text-decoration: none;
	}
}

.header_sublist__card {
}

.header_sublist__img_w {
	position: relative;

	display: block;
	width: 100%;
	padding-bottom: 69.8%;

	&:not(:last-child) {
		margin-bottom: 2.6rem;
	}
}

.header_sublist__img {
	position: absolute;
	top: 0;
	left: 0;

	@include cover_img;
}

.header_sublist__card_wrap {
}

.header_sublist__card_subtitle {
	@include text;

	line-height: 1.5;
	font-weight: 500;
	color: $gray_2;
	letter-spacing: 0.11em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}
}

.header_sublist__card_title {
	@include h4;

	letter-spacing: -0.014em;
	line-height: 1.14;
}
