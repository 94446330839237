// texts
.woocommerce .checkout.woocommerce-checkout h4 {
	width: 100%;
	position: relative;

	padding-bottom: 1.3rem;

	color: $gray_2;
	font-size: 1.6rem;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: .09em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 3.3rem;
	}

	&:after {
		position: absolute;
		left: 0;
		bottom: 0;

		display: block;
		width: calc(100% - 2.6rem);
		height: 1px;
		background-color: $beige_2;

		content: '';
	}
}

.woocommerce .checkout.woocommerce-checkout p {
	width: 100%;

	@include form_el_dark_label;

	&:not(:last-child) {
		margin-bottom: 1px;
	}
}

.woocommerce .checkout.woocommerce-checkout strong {
	display: block;
	width: 100%;

	color: $black;
	font-size: 2rem;
	font-weight: 500;

	&:not(:last-child) {
		margin-bottom: 2.7rem;
	}
}

// grid
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields {
	display: flex;
	flex-wrap: wrap;

	@include bp($point_2, min) {
		margin-right: -2.6rem;
	}
	@include bp($point_3) {
		margin-right: -1.5rem;
	}
}

.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row {
	
	@include bp($point_2, min) {
		padding-right: 2.6rem;
		margin-bottom: 2.1rem;
	}
	@include bp($point_3) {
		padding-right: 1.5rem;
		margin-bottom: 1.2rem;
	}
}

.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row label {
	@include form_el_dark_label;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

// inputs
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row input.input-text {
	@include form_el_default;
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-short input.input-text {
	height: 4.4rem;
}

// fields width
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-first,
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-last {
	@include bp($point_4, min) {
		width: 50%;
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-wide {
	width: 100%;
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-wide1 {
	@include bp($point_4, min) {
		width: 74.2%;
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-narrow1 {
	@include bp($point_4, min) {
		width: 39.1%;
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-narrow2 {
	@include bp($point_4, min) {
		width: 36.3%;
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-narrow3 {
	@include bp($point_4, min) {
		width: 25.8%;
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-billing-fields .form-row.form-row-narrow4 {
	@include bp($point_4, min) {
		width: 24.6%;
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}

// checkbox
.woocommerce .checkout.woocommerce-checkout .woocommerce-form__label-for-checkbox {
	display: block;
	width: 100%;
	letter-spacing: -0.07em;

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 5.6rem;
		}
		@include bp($point_3) {
			margin-bottom: 4rem;
		}
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-form__label-for-checkbox input.woocommerce-form__input-checkbox {
	display: none;

	&:checked + span::after{
		transform: rotateZ(40deg) scaleX(0.6) translateY(-3px);
		opacity: 1;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-form__label-for-checkbox span {
	@include form_el_checkbox;

	&::after{
		top: 2px;
	}
	&::before{
		top: -2px;
	}
}

.woocommerce .checkout.woocommerce-checkout .woocommerce-form__label-for-checkbox span b {
	font-weight: 500;
	letter-spacing: -.014em;
}

// radio
.woocommerce .checkout.woocommerce-checkout .woocommerce-checkout-payment {
	&:not(:last-child) {
		margin-bottom: 3rem;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-checkout-payment .wc_payment_method.payment_method_bacs{
	&:not(:last-child) {
		margin-bottom: 1.6rem;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-checkout-payment .wc_payment_method.payment_method_bacs .input-radio{
	display: none;

	&:checked + label::after{
		opacity: 1;
	}
}
.woocommerce .checkout.woocommerce-checkout .woocommerce-checkout-payment .wc_payment_method.payment_method_bacs .payment_method_bacs{
	&::after{
		opacity: 0;
	}
	
	@include form_el_radio;
}

// other
.woocommerce .checkout.woocommerce-checkout hr {
	height: 1px;
	width: 100%;
	border: 0;

	background-color: $beige_2;

	&:not(:last-child) {
		margin-bottom: 2.6rem;
	}
}

.woocommerce .checkout.woocommerce-checkout button[type=submit]{
	@include btn_default_size;
	@include btn_color_gold;

	@include bp($point_6 - 1) {
		width: 100%;
	}
}
