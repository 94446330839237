.header_top {
	padding: 2rem var(--gl-indent) 1.8rem;
	background-color: $white;

	@include bp($point_3) {
		display: none;
	}
}

.header_top__wrap {
	@include flex_block(row, nowrap, flex-start, center);
}

.header_top__block {
	@include flex_block(row, nowrap, flex-start, center);

	margin-left: auto;
}

.header_top__menu {
	&:not(:last-child) {
		margin-right: 4.6rem;
	}
}

.header_top__menu_list {
	@include flex_block(row, wrap, flex-start, flex-start);

	margin: 0 -3.6rem -2rem 0;
}

.header_top__menu_item {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	flex-shrink: 0;
	padding: 0 3.6rem 2rem 0;
}
