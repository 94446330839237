.form_textarea__label {
	@include form_el_label;
}

.form_textarea__element {
	@include form_el_default;

	@at-root {
		textarea#{&} {
			padding-top: 2rem;
			padding-bottom: 2rem;
	
			height: 13.8rem;
			line-height: normal;
		}
	}

	.get_in_touch & {
		height: 14.8rem;
		line-height: normal;
	}
}