@mixin form_el_checkbox {
	position: relative;

	padding-left: 4rem;

	font-size: 1.6rem;
	letter-spacing: inherit;
	color: $black;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;

		cursor: pointer;
		content: "";
	}

	&:after {
		top: 1px;
		left: 0.4rem;

		width: 1.6rem;
		height: 1.6rem;

		.filter_mobile & {
			width: 1.2rem;
			height: 1.2rem;
		}

		.product_subscription__subscribe & {
			@include bp($point_3) {
				top: .2rem;
				width: 1.2rem;
				height: 1.2rem;
			}
		}
	}

	&:before {
		top: -3px;
		left: 0;

		width: 2.6rem;
		height: 2.6rem;
		// border-radius: 2px;
		border: 1px solid $gold_2;

		transition: transform 0.2s, opacity 0.2s;
		will-change: transform;

		.form_checkbox__element:checked + & {
			// opacity: 0;
			// transform: rotateZ(40deg) scaleX(0.6) translateY(-3px);
		}

		.filter_mobile & {
			width: 2.2rem;
			height: 2.2rem;
		}

		.product_subscription__subscribe & {
			@include bp($point_3) {
				width: 2.2rem;
				height: 2.2rem;
			}
		}
	}

	&::after {
		opacity: 0;

		border-bottom: 2px solid $gold_2;
		border-right: 3px solid $gold_2;

		transition: transform 0.2s, opacity 0.2s;

		.form_checkbox__element:checked + & {
			transform: rotateZ(40deg) scaleX(0.6) translateY(-3px);
			opacity: 1;
		}
	}

	.filter_mobile & {
		padding-left: 3.4rem;

		letter-spacing: -0.01em;
		line-height: 1.5;
	}

	.product_subscription__subscribe & {
		@include bp($point_3) {
			padding-left: 3.2rem;
			font-size: 1.2rem;
			letter-spacing: -.01em;
		}
	}
	//--------------------------------------------- Font icon version
	// &:after {
	// 	@include icon-check;
	// 	@include transition_opacity;

	// 	text-align: center;
	// 	line-height: 2rem;
	// 	opacity: 0;
	// 	color: $black;

	// 	.form_checkbox__element:checked + & {
	// 		opacity: 1;
	// 	}
	// }
}

@mixin form_el_checkbox_small($w: 3rem, $h: 3rem, $iconW: 2rem, $iconH: 2rem, $padding: 5rem, $left: 0.4rem) {
	position: relative;

	padding-left: $padding;

	letter-spacing: inherit;
	color: $black;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;

		cursor: pointer;
		content: "";
	}

	&:after {
		top: 1px;
		left: $left;

		width: $iconW;
		height: $iconH;

		opacity: 0;

		border-bottom: 0.2rem solid $gold_2;
		border-right: 0.3rem solid $gold_2;

		transition: transform 0.2s, opacity 0.2s;

		.form_checkbox__element:checked + & {
			transform: rotateZ(40deg) scaleX(0.6) translateY(-3px);
			opacity: 1;
		}
	}

	&:before {
		top: -0.2rem;
		left: 0;

		width: $w;
		height: $h;
		border: 1px solid $input_border_color;

		transition: transform 0.2s, opacity 0.2s, border-color 0.3s;
		will-change: transform;

		.form_checkbox__element:checked + & {
			border-color: $gold_2;
		}
	}

	@include bp($point_2, min) {
		font-size: 1.6rem;
	}
	@include bp($point_3) {
		font-size: 1.2rem;
	}
}
