// @import "./breadcrumbs-block";
.hero_v2 {

	@include bp($point_2, min) {
		padding: 18.7rem var(--gl-indent) 4.9rem;
		min-height: 37.2rem;
	}

	@include bp($point_3) {
		padding: 15.2rem var(--gl-indent) 3.3rem;
	}
}

.hero_v2__bg_w {
	@include stretch;

	display: block;
	width: 100%;
	height: 100%;
}

.hero_v2__bg {
	@include cover_img;
}

.hero_v2__wrap {
	position: relative;
	z-index: 1;
}

.hero_v2__title {
	@include h1;

	line-height: 1;
	
	color: $white;

	&:not(:last-child) {
		margin-bottom: 1.3rem;
	}

	
	@include bp($point_2, min) {
		letter-spacing: -0.017em;
	}

	@include bp($point_3) {
		letter-spacing: -.026em;
	}
}

.hero_v2__content {
	@include bp($point_4, min) {
		@include flex_block(row, nowrap, flex-start);
	}
}

.hero_v2__call {
	color: $white;
	
	a {

		color: inherit;
		font-weight: 500;

		span {
			border-bottom: 2px solid $gold_2;
		}

		&::after{
			content:'';
			display: inline-block;

			width: 0;
			height: 0;

			@include bp($point_2, min) {
				margin: 0 0 .5rem .7rem;

				border-top: .5rem solid $gold_2;
				border-left: .6rem solid transparent;
				border-right: .6rem solid transparent;
			}
			@include bp($point_3) {
				margin: 0 0 .2rem .5rem;

				border-top: .6rem solid $gold_2;
				border-left: .5rem solid transparent;
				border-right: .5rem solid transparent;
			}
		}

		&:hover,
		&:focus{
			text-decoration: none;

			color: $gold_2;
		}

		@include transition_color;
		
		@include bp($point_2, min) {
			margin: 0 .7rem;
		}
		@include bp($point_3) {
			margin-right: .6rem;
		}
	}

	@include bp($point_2, min) {
		font-size: 2.2rem;
		line-height: 1.45;
	}
	@include bp($point_3) {
		font-size: 1.6rem;
		letter-spacing: -.012em;
		line-height: 1.5;
	}
}

.hero_v2__text {

	color: $white;

	&:not(:last-child) {
		margin-right: 2rem;
	}

	@include bp($point_2, min) {
		width: 75%;
		
		font-size: 1.8rem;
		line-height: 1.65;
	}

	@include bp($point_3) {
		font-size: 1.6rem;
		letter-spacing: -.035em;
		line-height: 1.5;
	}

	@include bp($point_4 - 1) {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.hero_v2__btn {
	margin-left: auto;

	@include bp($point_6 - 1) {
		width: 100%;
	}
}
