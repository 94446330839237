@mixin cover_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

@mixin contain_img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: contain;
}

@mixin popup {
	@include flex_block(row, nowrap, flex-start);

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100vh;
	padding: 6rem var(--gl-indent);

	overflow-y: auto;

	background-color: rgba($black, .75);

	opacity: 0;
	pointer-events: none;
	transition: opacity .3s;// значение милисекунд должно совпадать с "const timeout" (300) в initPopup.js

	z-index: 1000;

	&.active_state {
		opacity: 1;
		pointer-events: initial;
	}
}
