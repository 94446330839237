.btn_1 {
	@include btn_default_size;
	@include btn_color_gold;

	.popup_email &,
	.offer_content & {
		@include bp($point_3) {
			width: 100%;
		}
	}

	.step_form__btn & {
		@include bp($point_5) {
			width: 100%;
		}
	}

	.product_card__btn & {
		@include bp($point_5) {
			width: 100%;
		}
	}
}

.btn_2 {
	@include btn_default_size;
	@include btn_color_transparent;

	.product_card__link &,
	.product_card__btn & {
		@include bp($point_5) {
			width: 100%;
		}
	}
}

.btn_3 {
	@include btn_default_size;
	@include btn_color_transparent_2;
}

.btn_4 {
	@include btn_default_size;
	@include btn_color_transparent_3;
}

.btn_5 {
	@include btn_default_size;
	@include btn_color_black;

	.step_form__btn & {
		@include bp($point_6 - 1) {
			width: 100%;
		}
	}
}

.btn_6 {
	@include btn_wide_size;
	@include btn_color_white;
}

.btn_7 {
	@include btn_color_gold_mobile;
}

.btn_icon {
	@include btn_icon;

	.btn_6 & {
		height: 1.4rem;
	}
}

.slider_btn_1 {
	@include slider_button;

	flex-shrink: 0;

	.slider_btn_1__icon {
		@include bp($point_2, min) {
			width: 1.2rem;
			height: 2.2rem;
		}
		@include bp($point_3) {
			width: 1rem;
			height: 2rem;
			margin-left: .2rem;
		}
	}
}

.slider_btn_2 {
	@include slider_button_bordered;

	flex-shrink: 0;

	.slider_btn_2__icon {
		width: 0.8rem;
		height: 1.8rem;
	}
}

.slider_btn_3 {
	@include slider_button_big;

	.slider_btn_3__icon {
		@include bp($point_2, min) {
			width: 1.4rem;
			height: 2.8rem;
			margin-left: 0.4rem;
		}

		@include bp($point_3) {
			width: 1rem;
			height: 1.8rem;
			margin-left: 0.4rem;
		}
	}
}

.slider_btn_1--prev_mod,
.slider_btn_2--prev_mod {
	transform: rotate(-180deg);
}

.btn_desktop {
	@include bp($point_3) {
		display: none;
	}
}

.btn_mobile {
	@include bp($point_2, min) {
		display: none;
	}
}
