//---------------------------------------------sections
.section {
	position: relative;
	width: 100%;
	font-size: 1rem;

	@include bp($point_2, min) {
		padding: 6.2rem var(--gl-indent) 6.2rem;
	}

	@include bp($point_3) {
		padding: 5.2rem var(--gl-indent);
	}

	&.section--mobile_mod {
		@include bp($point_2, min) {
			display: none;
		}
	}

	&.section--decor_bottom_mod {
		&:after {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;

			display: block;

			height: 1px;
			background-color: $beige_2;
			content: '';
			@include bp($point_4, min) {
				width: $page_width;
			}

			@include bp($point_4 - 1) {
				width: calc(100% - var(--gl-indent)*2);
			}
		}
	}
}

.section_in {
	max-width: $page_width;
	width: 100%;
	margin: 0 auto;
}
