// components

@import "./header-components/header-sublist";
@import "./header-components/nav-shop";
@import "./header-components/header-mobile";
@import "./header-components/header-bar";
@import "./header-components/header-top";
@import "./header-components/header-bottom";
@import "./header-components/header-menu";
@import "./header-components/header-item";
@import "./header-components/header-mobile-bottom";

//------------------------------------------------------------layout
.header {
	--aditional-height: 0;

	position: fixed;
	top: 0;
	left: 0;

	// height: var(--height-header);
	width: 100%;

	font-size: 1rem;

	will-change: transform;
	transition: transform 0.2s ease;

	@include z-index(header);

	&:not(.body--open_menu_state &) {
		height: calc(var(--height-header) + var(--aditional-height));
	}

	.body--open_menu_state & {
		@include bp($point_2, min) {
			height: min-content;
		}

		@include bp($point_3) {
			height: 100%;
		}
	}

	&.header--scroll_state {
		box-shadow: 0 1px 4px rgba($black, 0.2);
		background-color: $white;
	}

	&.header--pos_state {
		transform: translateY(-105%);
	}
}

.header_in {
	width: 100%;
	max-width: $page_width;
	margin: 0 auto;
}

.header__logo {
	flex-shrink: 0;
	display: block;

	color: $black;

	&:not(:last-child) {
		margin-right: 2rem;
	}

	@include bp($point_4, min) {
		max-width: 20.6rem;
		height: 4.2rem;
	}

	@include bp($point_4 - 1) {
		max-width: 12rem;
		height: 5rem;
	}
}

.header__logo_img {
	@include contain_img;
}

//------------------------------------------------------------layout###

// search
@mixin search-icon {
	width: 2rem;
	height: 2rem;

	color: $gold_2;
}

.header__wrapper {
	max-width: 25.6rem;
	min-height: 2.4rem;
	width: 100%;
	margin-left: auto;

	@include flex_block(row, nowrap, flex-start, flex-start);
}

.header_form {
	position: relative;

	width: 100%;

	// for click area
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;

		width: 74%;
		height: 100%;

		.header__wrapper--open_mod & {
			pointer-events: none;
		}
	}
}

.header_form__wrap {
	display: flex;
	width: 100%;

	cursor: pointer;
	overflow: hidden;

	.form_input {
		width: 100%;
	}
}

.header_form__icon {
	position: absolute;
	top: -0.1rem;
	right: -0.4rem;

	cursor: pointer;
	pointer-events: none;

	.header__wrapper--open_mod & {
		pointer-events: auto;
	}

	@include search-icon;
}
