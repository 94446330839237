.map {
	.article__content & {
		margin: 4rem 0 5.4rem;
	}
}

.map__container {
	width: 100%;

	&:not(.get_in_touch_contacts__map &) {
		@include bp($point_4, min) {
			height: 32.8rem;
		}

		@include bp($point_4 - 1) {
			padding-top: 60%;
		}
	}

	.get_in_touch_contacts__map & {
		height: 25.6rem;
	}
}
