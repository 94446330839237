.ui_form__list {
	margin: 0 -2rem -2rem 0;

	@include flex_block(row, wrap, flex-start);
}

.ui_form__item {
	padding: 0 2rem 2rem 0;

	@include bp($point_4, min) {
		&:not(.ui_form__item--full_mod) {
			width: 50%;
		}
	
		&.ui_form__item--full_mod {
			width: 100%;
		}
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.ui_form__title {
	@include form_el_label;
}

.ui_form__subitem {
	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}
}