.breadcrumbs_block__list {
	@include flex_block(row, nowrap, flex-start);

	position: relative;
	z-index: 1;

	&.breadcrumbs_block--position_mod_1 {
		position: absolute;
		top: 2.3rem;
	}

	&.breadcrumbs_block--position_mod_2 {
		position: absolute;

		@include bp($point_4, min) {
			top: 2.3rem;
		}
		@include bp($point_4 - 1) {
			top: 2rem;
		}
	}

	@include bp($point_4, min) {
		&.breadcrumbs_block__list--mobile_mod {
			display: none;
		}
	}
	@include bp($point_4 - 1) {
		&.breadcrumbs_block__list--desktop_mod {
			display: none;
		}
	}
}

.breadcrumbs_block__item {
	font-weight: 500;
	letter-spacing: -0.02em;

	@include flex_block(row, nowrap, flex-start, center);

	@include bp($point_4, min) {
		font-size: 1.3rem;
	}
	@include bp($point_4 - 1) {
		font-size: 1.2rem;
	}
}

.breadcrumbs_block__link {
	@include transition_opacity;

	.breadcrumbs_block__item:not(:last-child) & {
		@include bp($point_4, min) {
			margin-right: 0.6rem;
		}
		@include bp($point_4 - 1) {
			margin-right: 4px;
		}
	}

	.breadcrumbs_block--position_mod_1 & {
		color: $white;
	}

	.breadcrumbs_block--position_mod_2 &,
	.single_product__breadcrumbs &,
	.hero_venison & {
		color: $black;
	}

	.single_product__breadcrumbs & {
		padding-left: 5px;
	}

	.breadcrumbs_block--color_mod_1 & {
		color: $black;
	}

	&:last-child {
		cursor: default;
		text-decoration: none;
	}

	&:hover {
		&:not(:last-child) {
			opacity: 0.7;
			text-decoration: none;
			cursor: pointer;
		}
	}
}

.breadcrumbs_block__icon_w {
	.breadcrumbs_block__item:not(:last-child) & {
		@include bp($point_4, min) {
			margin-right: 0.6rem;
		}
		@include bp($point_4 - 1) {
			margin-right: 3px;
		}
	}

	.breadcrumbs_block--position_mod_1 & {
		color: $gold_2;
	}

	.breadcrumbs_block--position_mod_2 & {
		color: $black;
	}

	.article &,
	.hero_venison &,
	.mbs_slider & {
		color: $gold_2;
	}

	.breadcrumbs_block--color_mod_1 & {
		color: $gold_2;
	}

	svg {
		fill: currentColor;
	}

	@include bp($point_4, min) {
		width: 0.5rem;
		height: 0.9rem;
	}
	@include bp($point_4 - 1) {
		width: 0.4rem;
		height: 0.8rem;
	}
}
