.header_mobile_bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;

	padding: 2.4rem 3.2rem;
	background-color: $white;
}

.header_mobile_bottom__wrap {
	position: relative;
	padding-bottom: 2.2rem;

	width: 100%;

	&:not(:last-child) {
		margin-bottom: 2.8rem;
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 1px;

		background-color: $gray_12;
	}
}

.header_mobile_bottom__list {
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 0 -5.8rem -2rem 0;
}

.header_mobile_bottom__item {
	padding: 0 6.2rem 2rem 0;
}

.header_mobile_bottom__link {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
	font-size: 1.5rem;
	color: $black;
	letter-spacing: -0.01em;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.header_mobile_bottom__icon {
	width: 2rem;
	height: 2rem;
	padding-left: 5px;

	color: $gold_2;
}

.header_mobile_bottom__language {
	&:not(:last-child) {
		margin-bottom: 1.8rem;
	}
}

.header_mobile_bottom__info {
	text-align: center;

	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}
}

.header_mobile_bottom__email,
.header_mobile_bottom__tel {
	display: block;

	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.86;
	color: $gold_2;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $gold_1;
	}
}

.header_mobile_bottom__tel {
	letter-spacing: 0.068em;
}

.header_mobile_bottom__social_list {
	@include flex_block(row, nowrap, flex-end, center);
	margin: 0 -2rem -2rem 0;
}

.header_mobile_bottom__social_item {
	padding: 0 2rem 2rem 0;
}

.header_mobile_bottom__social_link {
	display: block;
	border-radius: 50%;

	width: 4.4rem;
	height: 4.4rem;

	color: $gold_2;
	transition: color 0.3s ease;

	&:focus,
	&:hover {
		color: darken($gold_2, 10%);
	}
}
