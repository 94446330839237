// @import "./breadcrumbs-block";

.hero_resipes {
	// padding-top: 11.3rem;
	// padding-bottom: 1.1rem;
}

.hero_resipes_in {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		bottom: -1.1rem;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $beige_2;
	}
}

.hero_resipes__subtitle {
	font-size: 1.8rem;
	font-weight: 500;
	letter-spacing: 0.11em;

	color: $gray_2;

	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 1.8rem;
	}
}

.hero_resipes__title {
	@include h2;

	letter-spacing: -0.018em;
	line-height: 1;
	max-width: 90%;

	&:not(:last-child) {
		margin-bottom: 4.5rem;
	}
}

.hero_resipes__row {
	@include flex_block(row, nowrap, flex-start, center);
}

.hero_resipes__date {
	@include text;

	color: $gold_2;
}

.hero_resipes__list {
	@include flex_block(row, nowrap, flex-start);

	margin-left: 23.6%;
}

.hero_resipes__item {
	@include flex_block(row, nowrap, flex-start, center);
	@include text;

	color: $gold_2;

	&:not(:last-child) {
		margin-right: 3rem;
	}
}

.hero_resipes__icon_w {
	width: 3.2rem;
	height: 3.2rem;

	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}

.hero_resipes__link {
	@include text;

	margin-left: auto;

	font-weight: 500;

	color: $gold_2;

	border-bottom: 1px solid $gold_2;
}
