@import "ScssHelpers/helpers-all.scss";

.nav_res {
	// background-color: $white;
	// padding: 2.8rem 0;

	// position: absolute;
	// bottom: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;
	// min-height: 57.2rem;

	// opacity: 0;
	// z-index: -1;
	// pointer-events: none;

	// background-color: $beige_1;
	// transform: translateY(100%);
	// transition: opacity ease-in-out .3s;

	// .header_menu__item:hover & {
	// 	opacity: 1;
	// 	z-index: 1;
	// 	pointer-events: auto;
	// }
}

.naw_res__list {
	@include flex_block(row, wrap, flex-start, stretch);

	height: 100%;
	margin: 0 -2.4rem -2.4rem 0;
}

.naw_res__item {
	padding: 0 2.4rem 2.4rem 0;

	@include bp($point_2, min) {
		width: 33.333%;
	}

	@include bp($point_3, $point_4) {
		width: 50%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.naw_res__link {
	position: relative;
	display: block;

	&::before {
		z-index: 1;

		width: 100%;
		height: 100%;

		background-image: linear-gradient(5deg, $black 0%, rgba($black, 0) 100%);
		opacity: 0.6;
		content: "";

		@include stretch;
		@include transition_opacity;
	}

	&:focus,
	&:hover {
		text-decoration: none;

		&::before {
			opacity: 0;
		}
	}
}

.naw_res__title {
	z-index: 2;
	position: absolute;
	bottom: 0;
	left: 0;

	width: 100%;
	padding: 2rem 2rem 3rem 4rem;

	font-weight: 500;
	color: $white;

	@include bp($point_2, min) {
		font-size: 4.2rem;
		letter-spacing: -0.01em;
	}
	@include bp($point_3) {
		font-size: 2.6rem;
		letter-spacing: -0.024em;
	}
}

.naw_res__img {
	display: block;
	position: relative;

	width: 100%;
	height: 100%;
	background-color: $black;
	overflow: hidden;

	// padding-bottom: 115%;

	@include bp($point_2, min) {
		padding-bottom: 94%;
	}
	@include bp($point_3, $point_4) {
		padding-bottom: 50%;
	}
	@include bp($point_4 - 1) {
		padding-bottom: 30%;
	}
}

.naw_res__img_in {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	.naw_res__link:hover &,
	.naw_res__link:focus & {
		transform: scale(1.1);
	}

	@include transition_transform;
}

.naw_res__btn_w {
	// .btn_3 {
	// 	background-color: $gold_2;
	// 	color: $white;
	// 	width: 100%;
	// }

	.btn_icon {
		height: 1.4rem;
	}
}
