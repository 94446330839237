@mixin st_btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	text-align: center;
	white-space: nowrap;
	font-family: $font_1;

	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

//color mixins:
@mixin btn_color_gold {
	font-weight: 700;
	color: $white;
	background-color: $gold_2;
	border: 1px solid $gold_2;
	transition: background-color 0.3s ease, opacity 0.3s ease;

	@include st_btn;

	&:not(:disabled):hover {
		border-color: $brown_3;
		background-color: $brown_3;
	}

	&:disabled {
		opacity: 0.25;
		pointer-events: none;
	}

	.onboarding_2--active_state & {
		opacity: 1;
		pointer-events: all;
	}
}

@mixin btn_color_black {
	font-weight: 700;
	color: $white;
	background-color: $black;
	transition: opacity 0.3s ease;

	@include st_btn;

	&:hover {
		opacity: 0.7;
	}
}

@mixin btn_color_white {
	font-weight: 500;
	color: $white;
	background-color: $gold_2;
	transition: opacity 0.3s ease;

	@include st_btn;

	&:hover {
		opacity: 0.7;
	}
}

@mixin btn_color_transparent {
	font-weight: 500;
	color: $gold_2;
	border: 1px solid $gold_2;
	background-color: transparent;
	transition: background-color 0.3s ease;

	@include st_btn;

	&:hover {
		border-color: $brown_3;
		background-color: $brown_3;
		color: $white;
	}
}

@mixin btn_color_transparent_2 {
	font-weight: 700;
	color: $black;
	border: 1px solid $gold_2;
	background-color: transparent;
	transition: background-color 0.3s ease;

	@include st_btn;

	&:hover {
		border-color: $brown_3;
		background-color: $brown_3;
		color: $white;
	}
}

@mixin btn_color_transparent_3 {
	font-weight: 700;
	color: $white;
	border: 1px solid $white;
	background-color: transparent;
	transition: background-color 0.3s ease;

	@include st_btn;

	&:hover {
		border-color: $white;
		background-color: $white;
		color: $gold_2;
	}
}

@mixin btn_style_gold {
	// 	font-weight: 700;
	// 	color: $white;
	// 	background-color: $gold_2;
	// 	transition: background-color .3s ease;

	// 	@include st_btn;

	// 	&:hover {
	// 		// add hover
	// 	}
}

@mixin btn_style_gold_bordered {
	// 	font-weight: 700;
	// 	color: $brown;

	// 	border: 1px solid $gold_2;
	// 	background-color: transparent;

	// 	transition: background-color .3s ease;
	// 	cursor: pointer;

	// 	@include st_btn;

	// 	&:hover {
	// 		// add hover
	// 	}
}

@mixin btn_style_white_bordered {
	// 	font-weight: 700;
	// 	color: $white;

	// 	border: 1px solid $white;
	// 	background-color: transparent;

	// 	transition: background-color .3s ease;
	// 	cursor: pointer;

	// 	@include st_btn;

	// 	&:hover {
	// 		// add hover
	// 	}
}

@mixin btn_color_gold_mobile {
	color: $gold_2;
	font-size: 1.5rem;
	line-height: 1.1;
	font-weight: 500;
	letter-spacing: -0.02em;

	border-bottom: 1px solid $gold_2;

	@include st_btn;
}

//size mixins:
@mixin btn_default_size {
	letter-spacing: -0.2px;
	cursor: pointer;

	@include bp($point_2, min) {
		padding: 0 2.2rem;
		height: 4.8rem;
		line-height: 4.6rem;
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		padding: 0 1.6rem;
		height: 4.4rem;
		line-height: 4.4rem;
		font-size: 1.4rem;
	}
}

@mixin btn_wide_size {
	width: 100%;
	height: 6rem;
	padding: 0 2.2rem;

	letter-spacing: 0.13rem;

	line-height: 6rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	cursor: pointer;
}

@mixin btn_icon {
	height: 0.8rem;
	width: 1.6rem;
	margin-left: 1rem;

	color: currentColor;

	.header_sublist & {
		color: $black;
	}
}

@mixin btn_size_normal {
	// height: 4.8rem;
	// padding: 0 2.6rem;

	// letter-spacing: -.01em;

	// line-height: 4.8rem;
	// font-size: 1.6rem;
}

//slider button
@mixin slider_button {
	border-radius: 50%;

	background-color: $gold_2;
	color: $white;

	transition: opacity 0.3s ease;

	cursor: pointer;
	transition: background-color 0.3s ease, opacity 0.3s ease;

	&:hover {
		background-color: $brown_3;
	}

	@include flex_block(row, nowrap, center, center, center);

	&.swiper-button-disabled {
		opacity: 0;
		pointer-events: none;
	}

	@include bp($point_2, min) {
		width: 5.6rem;
		height: 5.6rem;
	}
	@include bp($point_3) {
		width: 4.4rem;
		height: 4.4rem;
	}
}

@mixin slider_button_big {
	border-radius: 50%;

	background-color: $gold_2;
	color: $white;

	cursor: pointer;
	transition: background-color 0.3s ease, opacity 0.3s ease;

	&:hover {
		background-color: $brown_3;
	}

	@include flex_block(row, nowrap, center, center, center);

	@include bp($point_2, min) {
		width: 6.4rem;
		height: 6.4rem;
	}

	@include bp($point_3) {
		width: 4.4rem;
		height: 4.4rem;
	}
}

@mixin slider_button_bordered {
	border-radius: 50%;

	background-color: transparent;
	border: 1px solid $gold_2;
	color: $gold_2;

	transition: background-color 0.3s ease, color 0.3s ease;
	cursor: pointer;

	&:hover,
	&:focus {
		border-color: $brown_3;
		background-color: $brown_3;
		color: $white;
	}

	&.hero_slider__button {
		color: $white;
		border-color: $white;
	}

	@include flex_block(row, nowrap, center, center, center);

	@include bp($point_2, min) {
		width: 4.8rem;
		height: 4.8rem;
	}

	@include bp($point_3) {
		width: 3.6rem;
		height: 3.6rem;
	}
}

@mixin spoiler_acc($fz: 1.6rem) {
	position: relative;
	font-weight: 500;
	letter-spacing: -0.03em;
	line-height: 1.2;
	color: $gold_2;
	cursor: pointer;

	&:before {
		position: absolute;
		left: 0;
		bottom: -2px;

		display: block;
		width: 100%;
		background-color: $gold_2;
		transition: opacity 0.3s ease;

		content: "";
	}

	@include bp($point_2, min) {
		font-size: $fz;
		&:before {
			height: 2px;
		}
	}

	@include bp($point_3) {
		font-size: 1.4rem;
		&:before {
			height: 1px;
		}
	}

	&:focus,
	&:hover {
		&:before {
			opacity: 0;
		}
	}
}

@mixin spoiler_acc_icon {
	width: 1.2rem;
	height: 1.2rem;
	flex-shrink: 0;

	@include transition_transform;
}
