@import "ScssHelpers/helpers-all.scss";

.content_text {
	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 6rem;
		}
		@include bp($point_3) {
			margin-bottom: 3.5rem;
		}
	}
}

.content_text strong {
	display: block;

	font-weight: 400;

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 1.6rem;
		}
		@include bp($point_3) {
			margin-bottom: 1rem;
		}
	}

	.faq & {
		&:not(:last-child) {
			margin-bottom: 1.8rem;
		}
	}

	.steak_club_info & {
		&:not(:last-child) {
			margin-bottom: 1.4rem;
		}
	}

	@include bp($point_2, min) {
		line-height: 1.4;
		font-size: 2.2rem;
	}
	@include bp($point_3) {
		font-size: 1.6rem;
		line-height: 1.5;
		letter-spacing: -0.036em;
	}
}

.content_text p {
	@include text;

	@include bp($point_2, min) {
		line-height: 1.5;
	}
	@include bp($point_3) {
		line-height: 1.43;
		letter-spacing: -0.017em;
		color: rgba(0, 0, 0, 0.8);
	}
}
