@mixin form_el_radio {
	position: relative;

	font-weight: 500;
	color: $black;
	cursor: pointer;
	&:not(.sort_by_mobile &) {
		&:before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;

			border-radius: 100%;
			border: 1px solid $gold_2;
			cursor: pointer;

			@include bp($point_4, min) {
				width: 2.4rem;
				height: 2.4rem;
			}

			@include bp($point_4 - 1) {
				width: 2rem;
				height: 2rem;
			}
		}

		&:after {
			@include transition_opacity;

			content: "";
			position: absolute;
			top: 0.4rem;
			left: 0.5rem;

			border-radius: 100%;
			background: $gold_2;

			&:not(.checkout &) {
				opacity: 0;
			}

			.form_radio__element:checked + & {
				opacity: 1;
			}

			@include bp($point_4, min) {
				width: 1.4rem;
				height: 1.4rem;
			}

			@include bp($point_4 - 1) {
				width: 1rem;
				height: 1rem;
			}
		}
	}

	@include bp($point_4, min) {
		padding-left: 3.8rem;

		font-size: 1.6rem;
	}

	@include bp($point_4 - 1) {
		padding-left: 2.8rem;

		font-size: 1.4rem;
		letter-spacing: -0.016em;
	}

	.sort_by_mobile & {
		padding: 0.6rem 0;
		display: block;
		// width: 100%;

		font-size: 1.6rem;
		color: $black;
		letter-spacing: -0.014em;
		line-height: 1.5;

		&::after {
			content: "";
			@include transition_opacity;
			position: absolute;
			left: 50%;
			top: 0;

			width: 107%;
			height: 100%;

			z-index: -1;
			transform: translateX(-50%);

			background-color: $beige_1;

			opacity: 0;
		}

		&::before {
			@include transition_opacity;
			content: "";
			position: absolute;
			right: 1rem;
			top: 50%;

			z-index: 5;

			display: block;
			width: 0.8rem;
			height: 1.4rem;
			border: 2px solid $gold_2;
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg) translate(-58%, -58%);

			opacity: 0;
		}
	}
}

.form_radio__element:checked + .form_radio__label {
	&::before,
	&::after {
		opacity: 1;
	}
}
