.header_bar {
	padding: 1rem var(--gl-indent);
	background-color: $gold_2;
}

.header_bar__text {
	font-weight: 300;
	color: $white;

	strong {
		font-weight: 500;
	}
	
	a {
		position: relative;
		display: inline-block;

		color: inherit;
		font-weight: 500;

		&::before{
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 0;

			width: 100%;
			height: 2px;

			background-color: $white;
			content:'';
			
			@include transition_transform;
		}

		&:hover,
		&:focus{
			text-decoration: none;

			&::before{
				transform: scaleX(0);
			}
		}
	}

	@include bp($point_2, min) {
		font-size: 1.4rem;
	}
	@include bp($point_3) {
		font-size: 1.2rem;
	}
}