.prod_spotlight {
	background-color: $gray_3;

	// @include bp($point_2, min) {
	// 	padding-top: 0;
	// 	padding-bottom: 0;
	// }
	// @include bp($point_3) {
	// 	padding-top: 3rem;
	// 	padding-bottom: 3rem;
	// }

	@include bp($point_3) {
		padding-top: 0;
	}
}

.prod_spotlight__row {
	@include bp($point_2, min) {
		@include flex_block(row, wrap, flex-start, stretch, center);
	}
	@include bp($point_3) {
		@include flex_block(column, nowrap, flex-start, flex-start, flex-start);
	}
}

.prod_spotlight__col {
	@include bp($point_2, min) {
		&:first-child {
			width: 51%;
		}

		&:last-child {
			width: 49%;
		}
	}

	@include bp($point_3) {
		width: 100%;

		&:first-child {
			padding-top: 3rem;
		}

		&:last-child {
			order: -1;
			margin-left: calc(var(--gl-indent) * -1);
			width: calc(100% + var(--gl-indent) * 2);
		}
	}
}

.prod_spotlight__info {
	@include bp($point_2, min) {
		padding: 14.2rem 6.4rem 13.5rem 0;
	}
	// @include bp($point_3) {
	// 	padding-bottom: 4rem;
	// }
}

.prod_spotlight__shop {
}

.prod_spotlight__img_in {
	// @include cover_img;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	height: 100%;
	object-fit: cover;

	@include bp($point_2, min) {
		width: 49.2vw;
	}
	@include bp($point_3) {
		width: 100%;
	}
}

.prod_spotlight__img {
	position: relative;

	display: block;
	min-width: 100%;
	height: 100%;


	@include bp($point_2, min) {
		padding-bottom: 100%;
	}
	@include bp($point_3) {
		padding-bottom: 89%;
	}
}
