//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: "Geograph", sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;
$black_2: #070606;

$gold_1: #ce9c53;
$gold_2: #bb753a;

$gray_1: #989898;
$gray_2: #868686;
$gray_3: #f0f2f4;
$gray_4: #e8ded8;
$gray_5: #8f8f8f;
$gray_6: #414141;
$gray_7: #efefef;
$gray_8: #515151;
$gray_9: #d0d0d0;
$gray_10: #576063;
$gray_11: #808080;
$gray_12: #edeff4;

$green: #0a794d;

$brown: #bb826a;
$brown_2: #615656;
$brown_3: #8c592e;

$beige_1: #fcf5ec;
$beige_2: #f2e4d1;

$blue: #010718;

$shadow_1: 6px 6px 26px rgba(25, 49, 123, 0.3);
$shadow_2: 6px 6px 26px $black;

$gradient: linear-gradient(270deg, $gold_2 0%, #e5c69f 100%);
$gradient_2: linear-gradient(29deg, $gold_2 0%, rgba($black, 0) 100%);

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popup: 101,
	header: 100,
	footer: 100,
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$page_width: 122.4rem;
$page_width_2: 144rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_radius: 0.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1500px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4.8rem;
$input_height_mob: 4.4rem;
$input_offset: 0 1.6rem;
$input_font_size: 1.6rem;
$input_font_size_mob: 1.4rem;
$input_border_color: $gray_2;
$input_border_color_active: $black;
$input_placeholder_color: $black;
$input_text_color: $black;
$input_text_color_mob: rgba($black, 0.8);
$input_radius: $gl_radius;
//---------------------------------------------forms###
