.form_select__label {
	@include form_el_label;
}

.form_select__element,
.woocommerce-input-wrapper select {
	@include form_el_default;

	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTYgOCI+PGc+PGc+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYmI3NTNhIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIyMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xIDF2MGw3IDZ2MGw3LTZ2MCIvPjwvZz48L2c+PC9zdmc+");
	background-repeat: no-repeat;
	background-position: right 1.6rem center;

	&:hover,
	&:focus {
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTYgOCI+PGc+PGc+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYmI3NTNhIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIyMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIGQ9Ik0xIDF2MGw3IDZ2MGw3LTZ2MCIvPjwvZz48L2c+PC9zdmc+");
		background-repeat: no-repeat;
		background-position: right 1.6rem center;
	}
}

.form_select {
	width: 100%;
}

.form_select {
	.filters_product_list__form & {
		&:not(:last-child) {
			margin-right: 2.4rem;
		}
	}
}
