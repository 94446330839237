.form_input__label {
	@include form_el_label;
}

.form_input__dark_label {
	@include form_el_dark_label;
}

.form_input__element {
	@include form_el_default;
}

.form_input__field {
	position: relative;
}

.form_input__search_el {
	@include form_el_search;
}

.form_input__header_search {
	@include form_header_search;
}

.header_mobile__form_search {
	@include form_mobile_search;
}
