.header_menu {
	&:not(:last-child) {
		margin-right: 2rem;
	}
}

.header_menu__list {
	@include flex_block(row, wrap, flex-start, flex-start);

	margin: 0 -2.5rem -2.4rem 0;
}

.header_menu__item {
	flex-shrink: 0;
	padding: 0 2.5rem 2.4rem 0;
}

.header_menu__item_wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-bottom: 1.6rem;

	cursor: pointer;
}

.header_menu__label {
	position: relative;
	z-index: 5;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	font-size: 1.5rem;
	font-weight: 500;
	color: $black;

	transition: color 0.3s ease;
	overflow: hidden;

	&:not(:last-child) {
		margin-right: 0.6rem;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: -100%;

		content: "";

		width: 100%;
		height: 1px;

		background-color: $gold_2;
		transition: left 0.3s ease;

		.header_menu__item_wrap:hover &,
		.header_menu__item_wrap:focus & {
			left: 0;
		}
	}

	.header_menu__item_wrap:hover &,
	.header_menu__item_wrap:focus & {
		text-decoration: none;
	}
}

.header_menu__icon {
	flex-shrink: 0;
	width: 1rem;
	height: 0.5rem;

	color: $gold_2;

	transition: transform 0.3s ease;

	.header_menu__item_wrap:hover &,
	.header_menu__item_wrap:focus & {
		transform: rotate(-180deg);
	}
}
