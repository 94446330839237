.checkout {}

.checkout__head {
	max-width: 94rem;

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 4.2rem;
		}
		@include bp($point_3) {
			margin-bottom: 3.4rem;
		}
	}
}

.checkout__title {
	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	@include h3;
}

.checkout__text {
	@include text;

	@include bp($point_2, min) {
		line-height: 1.5;
	}
	@include bp($point_3) {
		line-height: 1.44;
	}
}

.checkout__row {
	@include bp($point_2, min) {
		display: flex;
		flex-wrap: wrap;

		margin: 0 -5.4rem -5.4rem 0;
	}
}

.checkout__column {
	@include bp($point_2, min) {
		padding: 0 5.4rem 5.4rem 0;

		&:nth-child(1){
			width: 34.9%;
		}
		&:nth-child(2){
			display: flex;
			width: 65.1%;
			order: -1;
		}
	}
	@include bp($point_3) {
		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}
}