.form_checkbox__block {
	display: block;
}

.form_checkbox__element {
	display: none;
}

.form_checkbox__label {
	@include form_el_checkbox;
}

.form_checkbox__label_small {
	@include bp($point_2, min) {
		@include form_el_checkbox_small;
	}
	@include bp($point_3) {
		@include form_el_checkbox_small($w: 2.4rem, $h: 2.4rem, $iconW: 1.4rem, $iconH: 1.6rem, $padding: 3.4rem, $left: .3rem);
	}
}

.form_checkbox__label_size_1 {
	@include form_el_checkbox_small($w: 2.4rem, $h: 2.4rem, $iconW: 1.4rem, $iconH: 1.6rem, $padding: 2.4rem, $left: .3rem);
}
