@mixin form_el_label {
	display: block;

	color: $gray_2;
	font-weight: 500;

	line-height: 1.5;
	text-transform: uppercase;

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 1rem;
		}
		@include bp($point_3) {
			margin-bottom: 0.6rem;
		}
	}

	@include bp($point_2, min) {
		font-size: 1.6rem;
		letter-spacing: 0.12em;
	}
	@include bp($point_3) {
		font-size: 1.2rem;
		letter-spacing: 0.09em;
	}
}

@mixin form_el_dark_label {
	display: block;

	font-size: 1.6rem;
	color: $black;
	line-height: 1.5;

	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}
}

@mixin form_el_default {
	display: block;
	width: 100%;
	font-family: $font_1;
	box-shadow: inset 0 0 0 1px $input_border_color;
	background-color: $white;
	border: none;
	background-clip: padding-box;

	transition: background 0.3s ease, box-shadow 0.3s ease;
	will-change: background, box-shadow;

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
	}
	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $input_placeholder_color;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $input_placeholder_color;
	}

	&::-webkit-contacts-auto-fill-button {
		visibility: hidden;
		position: absolute;
		right: 0;
	}

	&::-ms-clear {
		display: none;
		width: 0;
		height: 0;
	}
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	&:hover,
	&:focus {
		background: transparent;
		box-shadow: inset 0 0 0 2px $gold_2;
	}

	.get_in_touch &,
	.popup_email & {
		height: 4.4rem;
		line-height: 4.2rem;
	}

	.onboarding_form & {
		@include bp($point_2, min) {
			padding-left: 1.8rem;
		}
	}

	@include appearance(none);
	
	@include bp($point_2, min) {
		height: $input_height;
		padding: 0rem 4.6rem 0.4rem 1.4rem;

		font-size: $input_font_size;
		color: $input_text_color;
	}
	@include bp($point_3) {
		height: $input_height_mob;
		line-height: 4.2rem;
		padding: 0rem 2.6rem 0.4rem 1.6rem;

		font-size: $input_font_size_mob;
		color: $input_text_color_mob;
		letter-spacing: -0.016em;
	}
}

@mixin form_el_search {
	display: block;
	width: 100%;
	font-family: $font_1;
	background-color: $white;
	border: none;

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
		font-family: $font_1;
	}
	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $input_placeholder_color;
		font-family: $font_1;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $input_placeholder_color;
		font-family: $font_1;
	}

	&:hover,
	&:focus {
	}

	/* clears the 'X' from IE */
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	/* clears the 'X' from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	@include bp($point_2, min) {
		height: $input_height;
		padding: 0rem 4.6rem 0.4rem 1.4rem;

		font-size: $input_font_size;
		color: $input_text_color;
	}
	@include bp($point_3) {
		height: $input_height_mob;
		padding: 0rem 2.6rem 0.4rem 1.6rem;

		font-size: $input_font_size_mob;
		color: $input_text_color_mob;
		letter-spacing: -0.016em;
	}
}

@mixin form_header_search {
	display: block;
	width: 100%;
	height: 2.4rem;
	padding: 0 0 0.6rem 0;
	font-size: 1.5rem;
	font-family: $font_1;
	color: $gold_2;
	background-color: transparent;
	border: none;
	background-clip: padding-box;

	transition: transform 0.3s ease, box-shadow 0.3s ease;
	transform: translateX(74%);
	pointer-events: none;

	.header__wrapper--open_mod & {
		pointer-events: initial;
		box-shadow: inset 0 -1px 0 0 rgba($gold_2, 0.8);
		transform: translateX(0);
	}

	&:hover,
	&:focus {
		box-shadow: inset 0 -1px 0 0 $gold_2;
	}

	&::-webkit-input-placeholder {
		color: $gold_2;
	}
	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $gold_2;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $gold_2;
	}

	/* clears the 'X' from IE */
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	/* clears the 'X' from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

@mixin form_mobile_search {
	display: block;
	width: 100%;
	height: 2.7rem;
	padding: 0 0 0.6rem 0;
	font-size: 1.6rem;
	font-family: $font_1;
	color: $gold_2;
	background-color: transparent;
	border: none;
	background-clip: padding-box;

	pointer-events: initial;

	&::-webkit-input-placeholder {
		color: $gold_2;
	}
	&::-moz-placeholder,
	&:-moz-placeholder {
		color: $gold_2;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $gold_2;
	}

	/* clears the 'X' from IE */
	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}
	/* clears the 'X' from Chrome */
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	@include bp($point_2, min) {
		.header_mobile__form_input:hover &,
		.header_mobile__form_input:focus & {
			border-bottom: 1px solid $gold_2;
		}
	}
}
