.nav_shop__in {
	// padding-top: 2.8rem;
}

.nav_shop__wrap {
	@include bp($point_4, min) {
		@include flex_block(row, wrap, flex-start, flex-start);

		margin: 0 -2.4rem -3rem 0;
	}

	@include bp($point_4 - 1) {
	}
}

.nav_shop__column {
	@include bp($point_4, min) {
		flex-shrink: 0;
		width: 33.333%;

		padding: 0 2.4rem 3rem 0;
	}

	@include bp($point_4 - 1) {
		&:not(:last-child) {
			margin-bottom: 2.8rem;
		}
	}
}

.nav_shop__row {
	&:not(:last-child) {
		margin-bottom: 4.6rem;
	}
}

.nav_shop__title {
	position: relative;

	font-size: 1.4rem;
	font-weight: 500;
	color: $gray_2;

	line-height: 1.5;
	letter-spacing: 0.11em;
	text-transform: uppercase;

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;

		content: "";

		width: 100%;
		height: 1px;

		@include bp($point_2, min) {
			background-color: $beige_2;
		}

		@include bp($point_3) {
			background-color: $gray_12;
		}
	}

	@include bp($point_2, min) {
		padding-bottom: 0.8rem;

		&:not(:last-child) {
			margin-bottom: 1.2rem;
		}
	}

	@include bp($point_3) {
		padding-bottom: 1rem;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}
}

.nav_shop__items {
}

.nav_shop__item {
	@include bp($point_2, min) {
		&:not(:last-child) {
			margin-bottom: 0.8rem;
		}
	}
	@include bp($point_3) {
		&:not(:last-child) {
			margin-bottom: 1.2rem;
		}
	}
}

.nav_shop__link {
	display: block;

	color: $black;
	letter-spacing: -0.014em;

	transition: color 0.3s ease;

	&.nav_shop__link--size_mod {
		font-size: 1.6rem;
		font-weight: 400;
		line-height: 2;
		letter-spacing: normal;
	}

	&:hover,
	&:focus {
		color: $gold_2;
		text-decoration: none;
	}

	@include bp($point_2, min) {
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1.78;
	}
	@include bp($point_3) {
		font-size: 1.6rem;
		font-weight: 400;
		line-height: 1.5;
	}
}

.nav_shop__card {
	padding-top: 0.8rem;
}

.nav_shop__img_w {
	position: relative;

	display: block;
	width: 100%;
	padding-bottom: 61.2%;

	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}
}

.nav_shop__img {
	position: absolute;
	top: 0;
	left: 0;

	@include cover_img;
}

.nav_shop__card_wrap {
}

.nav_shop__card_subtitle {
	@include text;

	line-height: 1.5;
	font-weight: 500;
	color: $gray_2;
	letter-spacing: 0.11em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 0.8rem;
	}
}

.nav_shop__card_title {
	@include h4;

	letter-spacing: -0.014em;
	line-height: 1.14;
}
