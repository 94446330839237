.section_ui__title {
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}

	@include bp($point_4, min) {
		font-size: 7.2rem;
	}
	@include bp($point_4 - 1) {
		font-size: 6.2rem;
	}
}

.section_ui__text {
	font-size: 1.8rem;

	&:not(:last-child) {
		margin-bottom: 2.6rem;
	}

	
	p:not(:last-child) {
		margin-bottom: .6rem;
	}
}

.section_ui__category {
	font-weight: 700;

	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}

	@include bp($point_4, min) {
		font-size: 5.6rem;
	}
	@include bp($point_4 - 1) {
		font-size: 5.2rem;
	}
}