@import "hero-rich-text";

.hero_video {
	min-height: 63.6rem;
	display: flex;
	align-items: flex-end;
	// padding-bottom: 9.6rem;
}

.hero_video__bg {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.hero_video__bg__in {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.hero_video__col {

	@include bp($point_2, min) {
		width: 50%;
	}
}
