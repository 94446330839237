.hero_title {

	@include bp($point_2, min) {
		padding: 17.7rem var(--gl-indent) 4.6rem!important;
		min-height: 30.2rem;
	}

	@include bp($point_3) {
		padding: 14.9rem var(--gl-indent) 2.2rem;
	}
}

.hero_title_in {
}

.hero_title__img_w {
	@include stretch;

	display: block;
	width: 100%;
	height: 100%;
}

.hero_title__img {
	@include cover_img;
}

.hero_title__wrap {
	position: relative;
	z-index: 1;
}

.hero_title__title {
	@include h1;

	color: $white;

	@include bp($point_2, min) {
		letter-spacing: -0.018em;
	}

	@include bp($point_3) {
		letter-spacing: -0.03em;
	}
}
