.nav_about {
}

.nav_about__row {
	@include bp($point_4, min) {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;

		margin: 0 -2.4rem -2.4rem 0;
	}
}

.nav_about__column {
	@include bp($point_4, min) {
		padding: 0 2.4rem 2.4rem 0;

		&.nav_about__column--half_mod {
			width: 50%;
			// min-height: 17vw;
			min-height: max(24.8rem, 17vw);
		}
		&:not(.nav_about__column--half_mod) {
			width: 33.333%;
			// min-height: 12.5vw;
			min-height: max(17.6rem, 12.5vw);
		}
	}
	@include bp($point_4 - 1) {
		display: flex;
		flex-direction: column;

		width: 100%;
		min-height: 23.6vw;

		&:not(:last-child) {
			margin-bottom: 1.2rem;
		}
	}
}

.nav_about__link {
	position: relative;

	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::before,&::after{
		z-index: 2;

		width: 100%;
		height: 100%;

		content:'';
		pointer-events: none;
		
		@include stretch;
		@include transition_opacity;
	}
	&::before {
		@include bp($point_4, min) {
			background: linear-gradient(5deg, $black 0%, rgba($black, 0) 100%);
			opacity: 0.6;
		}
		@include bp($point_4 - 1) {
			background: linear-gradient(3deg, $black 0%, rgba($black, 0.05) 100%);
			opacity: 0.5;
		}
	}
	&::after{
		background: $gradient_2;
		opacity: 0;
	}

	&:hover,
	&:focus {
		text-decoration: none;

		&::before{
			opacity: 0;
		}
		&::after{
			opacity: .8;
		}
	}

	@include bp($point_4 - 1) {
		flex: 1 1 auto;

		display: flex;
		align-items: flex-end;
	}
}

.nav_about_img {
	z-index: 1;

	.nav_about__link:hover &,
	.nav_about__link:focus & {
		transform: scale(1.1);
	}

	background-color: $black;

	@include transition_transform;
	@include stretch;
}

.nav_about_img__in {
	@include cover_img;
}

.nav_about__content {
	position: relative;
	z-index: 3;

	width: 100%;
	height: 100%;

	@include bp($point_2, min) {
		.nav_about__column--half_mod & {
			padding: 2.8rem 3.6rem;
		}
		&:not(.nav_about__column--half_mod &) {
			padding: 2rem 3.6rem;
		}
	}
	@include bp($point_3) {
		padding: 1.5rem 1.9rem;
	}

	@include bp($point_4, min) {
		display: flex;
		align-items: flex-end;
	}
}

.nav_about__title {
	color: $white;
	font-weight: 500;
	letter-spacing: -0.016em;

	@include bp($point_2, min) {
		.nav_about__column--half_mod & {
			font-size: 4.2rem;
		}
		&:not(.nav_about__column--half_mod &) {
			font-size: 3.6rem;
		}
	}
	@include bp($point_3) {
		font-size: 2.6rem;
		font-weight: 500;
		letter-spacing: -0.024em;
	}
}
