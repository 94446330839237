.offer_content__subtitle {
	@include h5;

	text-transform: uppercase;

	@include bp($point_2, min) {
		margin-bottom: 3.2rem;
	}
	@include bp($point_3) {
		margin-bottom: 1.6rem;
	}
}

.offer_content__title {
	@include h2;

	color: $black;
	line-height: 100%;
	letter-spacing: -0.025em;

	@include bp($point_2, min) {
		margin-bottom: 2.4rem;
	}
	@include bp($point_3) {
		margin-bottom: .6rem;
	}
}

.offer_content__tags {
	@include flex_block(row, wrap, flex-start, center, center);

	@include bp($point_2, min) {
		margin-bottom: 1.5rem;
	}
	@include bp($point_3) {
		margin-bottom: 1rem;
	}
}

.offer_content__tag {
	position: relative;

	&:not(:last-child) {
		padding-right: 2.4rem;

		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 1.6rem;
			display: block;
			width: 0.2rem;
			height: calc(100% - 33%);

			background-color: $gray_2;
			transform: rotate(25deg) translateY(-50%);
			content: "";
		}
	}
}

.offer_content__link {
	margin-bottom: 2rem;
	color: $gray_2;
	font-weight: 500;
	letter-spacing: 0.05em;
	line-height: 133%;
	text-transform: uppercase;
	cursor: pointer;

	transition: opacity ease 0.3s;

	@include bp($point_2, min) {
		font-size: 1.8rem;
	}
	@include bp($point_3) {
		font-size: 1.4rem;
	}

	&:hover {
		text-decoration: none;
		opacity: 0.6;
	}
}

.offer_content__descr {
	@include text;

	color: $black;
	font-weight: 400;

	@include bp($point_2, min) {
		margin-bottom: 1.7rem;
		line-height: 1.5;
	}
	@include bp($point_3) {
		margin-bottom: 1.4rem;
		line-height: 1.44;
		letter-spacing: -.016em;
	}
}

.offer_content__rating {
	&:hover,
	&:focus {
		text-decoration: none;
	}

	@include flex_block(row, wrap, flex-start, center, center);


	@include bp($point_2, min) {
		margin-bottom: 1.5rem;
	}

	@include bp($point_3) {
		margin-bottom: .8rem;

		& > img {
			height: 1.2rem;
			margin-top: -2px;
		}
	}

	.star-rating {
		span {
			font-size: 1.4rem;
		}
	}


	.woocommerce-review-link {
		color: $gold_2;
		font-size: 1.4rem;
		font-weight: 400;
		letter-spacing: -0.22px;
		line-height: 138%;

		cursor: pointer;
	}
}

.offer_content__price_w {
	@include flex_block(row, wrap, flex-start, center, center);

	@include bp($point_2, min) {
		font-size: 2.4rem;

		&:not(:last-child) {
			margin-bottom: 2.4rem;
		}
	}
	@include bp($point_3) {
		font-size: 1.8rem;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}
}

.offer_content__price_old {
	margin-right: 1rem;

	color: $gray_2;
	font-size: inherit;
	font-weight: 300;
	letter-spacing: 0.04em;
	// letter-spacing: 0.1rem;
	text-decoration: line-through;
	text-transform: uppercase;
}

.offer_content__price_new {
	color: $black;
	font-size: inherit;
	font-weight: 500;
	// letter-spacing: 0.1rem;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}

.offer_content__buttons {
	display: flex;
	flex-wrap: wrap;

	margin: 0 -2rem -2rem 0;
}

.offer_content__item {
	padding: 0 2rem 2rem 0;

	@include bp($point_3) {
		width: 100%;
	}
}
