@mixin h1 {
	font-weight: 500;

	@include bp($point_2, min) {
		font-size: 6.4rem;
	}

	@include bp($point_3) {
		font-size: 3.4rem;
	}
}

@mixin h2 {
	font-weight: 500;

	@include bp($point_2, min) {
		font-size: 4.8rem;
	}

	@include bp($point_3) {
		font-size: 2.6rem;
	}
}

@mixin h3 {
	font-weight: 500;
	letter-spacing: -0.02em;

	@include bp($point_2, min) {
		font-size: 3.8rem;
	}

	@include bp($point_3) {
		font-size: 2.2rem;
	}
}

@mixin h4 {
	font-weight: 500;

	@include bp($point_2, min) {
		font-size: 2.8rem;
	}

	@include bp($point_3) {
		font-size: 1.8rem;
	}
}

@mixin h5 {
	font-weight: 500;
	letter-spacing: 0.11em;
	color: $gray_2;

	@include bp($point_2, min) {
		font-size: 1.8rem;
	}

	@include bp($point_3) {
		font-size: 1.4rem;

		.members_slider & {
			font-size: 1.2rem;
		}

		.memberships_tabs & {
			letter-spacing: 0.078em;
		}
	}
}

@mixin h6 {

	@include bp($point_2, min) {
		font-size: 2rem;
	}
	@include bp($point_3) {
		font-size: 1.6rem;
	}
}

@mixin text {
	font-weight: 400;

	@include bp($point_2, min) {
		font-size: 1.6rem;
	}

	@include bp($point_3) {
		font-size: 1.4rem;
	}
}
// offsets

@mixin title_offset {
	margin-bottom: 2rem;
}
