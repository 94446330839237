.hero_venison {
	// padding-top: 5.6rem;
	// padding-bottom: 4.2rem;

	&:not(:last-child) {
		margin-bottom: 2.9rem;
	}
}

.hero_venison__breadcrumbs {
	&:not(:last-child) {
		margin-bottom: 3.2rem;
	}

	@include bp($point_4 - 1) {
		display: none;
	}
}

.hero_venison__content {
	@include bp($point_2, min) {
		@include flex_block(row, nowrap, flex-start, stretch);
	}

	@include bp($point_4, $point_6) {
		padding-right: 6rem;
	}
}

.hero_venison__col {
	&:nth-child(2n - 1) {
		@include bp($point_2, min) {
			padding-top: 2.6rem;
		}
	}

	&:nth-child(2n - 2) {
		@include bp($point_2, min) {
			width: 15.2rem;
			height: 15.2rem;
			margin-left: auto;
			margin-right: -2.4rem;
		}
		@include bp($point_3) {
			position: absolute;
			top: 1.4rem;
			right: -0.8rem;

			width: 8.6rem;
			height: 8.6rem;
		}
	}

	@include bp($point_2, min) {
		flex-shrink: 0;

		&:not(:last-child) {
			margin-right: 2rem;
		}
	}
}

.hero_venison__title {
	@include h2;

	@include bp($point_2, min) {
		letter-spacing: -0.017em;

		&:not(:last-child) {
			margin-bottom: 1.2rem;
		}
	}

	@include bp($point_3) {
		letter-spacing: -0.025em;
		line-height: 1.08;

		&:not(:last-child) {
			margin-bottom: 1.4rem;
		}
	}

	@include bp($point_6) {
		max-width: 28rem;
	}
}

.hero_venison__text {
	max-width: 98rem;

	@include bp($point_2, min) {
		font-size: 1.8rem;
		line-height: 1.61;
	}

	@include bp($point_3) {
		// max-width: 76rem;
		// width: 100%;

		font-size: 1.6rem;
		line-height: 1.5;
		letter-spacing: -0.034em;
	}
}

.hero_venison__img {
	@include cover_img;
}
