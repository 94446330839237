.header_mobile {
	position: relative;

	@include bp($point_2, min) {
		display: none;
	}

	@include bp($point_3) {
		padding: 1.6rem var(--gl-indent);

		background-color: $white;

		// transition: margin 0.3s ease;

		// .body--open_menu_state & {
		// 	margin-right: 2rem;
		// }
		&:not(.header--scroll_state &) {
			border-bottom: 1px solid $beige_2;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;

			z-index: 4;

			transform: translateX(100%);
			background-color: $white;

			transition: transform 0.3s ease;

			.body--open_menu_state & {
				transform: translateX(0);
			}
		}
	}
}

.header_mobile__in {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;

	height: 100%;

	padding: 2.4rem 3.2rem;
	background-color: $white;

	@include bp($point_2, min) {
		height: 100%;
	}
}

.header_mobile__wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	align-content: center;
}

.header_mobile__col {
	&:nth-child(3n + 1),
	&:nth-child(3n + 3) {
		width: 25%;
	}

	&:nth-child(3n + 2) {
		width: 50%;
		padding: 0 1rem;
	}
}

.header_mobile__logo {
	flex-shrink: 0;
	display: block;

	max-width: 14.8rem;
	margin: 0 auto;
	height: 3.2rem;

	color: $black;
}

.header_mobile__logo_img {
	@include contain_img;
}

.header_mobile__body__search {
	&:not(:last-child) {
		margin-bottom: 1.4rem;
	}
}

.header_mobile__search_wrap {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: auto;

	padding: 1.4rem var(--gl-indent);

	background-color: $beige_1;

	transform: translateY(-100%);
	opacity: 0;
	visibility: hidden;

	transition: opacity 0.3s ease, transform 0.3s ease;

	&.header_mobile__search_wrap--state {
		transform: translateY(0);
		opacity: 1;
		visibility: visible;
	}
}

.header_mobile__search_wrap {
	margin-left: auto;
}

// @mixin user_menu_butt_line {
// 	position: absolute;
// 	top: 50%;
// 	left: 0;
// 	width: 100%;
// 	height: .4rem;
// 	margin-top: -.2rem;
// 	background-color: $gold_2;
// }

.header_mobile__trigger {
	position: relative;
	z-index: 5;

	width: 2rem;
	height: 1.8rem;

	overflow: hidden;
	cursor: pointer;

	&::before,
	&::after {
		@include transition_transform;

		content: "";
		position: absolute;
		top: 50%;
		right: 0;

		width: 100%;
		height: 2px;

		background-color: $gold_2;
	}

	&::before {
		transform: translateY(-0.5rem);
	}

	&::after {
		transform: translateY(0.5rem);
	}

	.body--open_menu_state & {
		&::before {
			transform: rotate(-45deg);
		}

		&::after {
			transform: rotate(45deg);
		}
	}
}

.header_mobile__trigge_decor {
	position: absolute;
	top: 50%;
	right: -0.4rem;

	width: 100%;
	height: 2px;

	background-color: $gold_2;

	transform: translateX(-50%);
	transition: opacity 0.3s ease;

	.body--open_menu_state & {
		opacity: 0;
	}
}

.header_mobile__icon_wrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.header_mobile__search {
	width: 2.6rem;
	height: 2.6rem;

	color: $gold_2;

	&:not(:last-child) {
		margin-right: 1.2rem;
	}

	transition: opacity 0.2s ease;
	.body--open_menu_state & {
		opacity: 0;
	}
}

.header_mobile__cart {
	transition: opacity 0.2s ease;
	.body--open_menu_state & {
		opacity: 0;
	}
}

.header_mobile__icon {
	position: relative;

	width: 2.2rem;
	height: 2.2rem;

	color: $gold_2;
}

.header_mobile__cart_count {
	position: absolute;
	top: -4px;
	right: -5px;

	width: 1.5rem;
	height: 1.4rem;

	@include flex_block(row, nowrap, center, center);

	font-size: 0.7rem;
	font-weight: 700;
	letter-spacing: 0.01em;
	color: $white;
	background-color: $gold_2;
	border-radius: 50%;
}

//================================================================

.header_mobile__body {
	display: flex;
	flex-direction: column;

	height: calc(var(--full-screen) - var(--height-header));
	// height: calc(100vh - var(--height-header));
	overflow-y: auto;
	overflow-x: hidden;

	// margin-right: 2rem;
	// position: absolute;
	// top: 100%;
	// left: 0;
	// width: 100%;
	// height: 100%;

	// z-index: 20;
	transition: transform 0.3s ease;
	transform: translateX(-100%);

	@include bp($point_2, min) {
		display: none;
	}

	.body--open_menu_state & {
		transform: translateX(0);
	}
}

.header_mobile__top {
	padding: 2.4rem 2.8rem 1.4rem 2.8rem;
	background-color: $beige_1;
}

.header_mobile__item {
	&:not(:last-child) {
		border-bottom: 2px solid $gray_12;
	}

	@include bp($point_2, min) {
		padding: 2rem 0 2.4rem;
	}

	@include bp($point_3) {
		padding: 2rem 0 2.2rem;
	}
}

.header_mobile__item_btn {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 100%;

	@include bp($point_3) {
		cursor: pointer;
	}
}

.header_mobile__item_title {
	font-size: 1.8rem;
	color: $black;
	font-weight: 500;
	letter-spacing: -0.01em;
	line-height: 1.33;

	&:not(:last-child) {
		margin-right: 2rem;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: $gold_2;
	}
}

.header_mobile__item_arrow {
	margin-left: auto;

	width: 2rem;
	height: 2.4rem;
	padding: 2px 0.6rem;

	color: $gold_2;
}

.header_mobile__form {
	border-bottom: 1px solid rgba($gold_2, 0.8);
}

.header_mobile__form_input {
	position: relative;

	width: 100%;
}

.header_mobile__search_icon {
	position: absolute;
	right: -1px;
	top: 1px;

	width: 2rem;
	height: 2rem;

	color: $gold_2;
}

.header_mobile__bottom {
	height: 100%;
	display: flex;
	flex-direction: column;

	background-color: $white;

	&.header_mobile__bottom--state {
		display: none;
	}
}
