.footer {
	width: 100%;
	min-height: var(--height-footer);
	flex: 0 0 auto;
	font-size: 1.6rem;
	will-change: transform;
}

.footer__head {
	position: relative;
	background-color: $gold_2;
	color: $white;

	.body--no_footer_head & {
		display: none;
	}

	@include bp($point_2, min) {
		padding: 2.4rem var(--gl-indent);
	}

	@include bp($point_3, $point_4) {
		padding: 2.4rem 8rem;
	}

	@include bp($point_4 - 1) {
		padding: 2rem var(--gl-indent) 1.5rem;
	}
}

.footer__head_list {
	@include bp($point_2, min) {
		@include flex_block(row, wrap, flex-start, center);
		margin: 0 -2rem -2rem 0;
	}
}

.footer__head_item {

	@include bp($point_2, min) {
		padding: 0 2rem 2rem 0;

		&.swiper-slide {
			width: 25%;
		}
	}

	@include bp($point_4, min) {
		@include flex_block(row, nowrap, flex-start, center, center);
	}
}

.footer__head_circle {
	flex-shrink: 0;
	border-radius: 50%;
	border: 1px solid $white;

	@include bp($point_4 - 1) {
		margin: 0 auto .8rem;
	}

	@include bp($point_2, min) {
		width: 4.8rem;
		height: 4.8rem;
	}

	@include bp($point_3) {
		width: 4rem;
		height: 4rem;
	}
}

.footer__head_content {
	@include bp($point_4, min) {
		padding-left: 1.8rem;
	}

	@include bp($point_4 - 1) {
		text-align: center;
		letter-spacing: -0.03em;
	}
}

.footer__head_title {
	font-weight: 500;
	line-height: 1;

	@include bp($point_2, min) {
		margin-bottom: .6rem;
		font-size: 1.8rem;
	}

	@include bp($point_3) {
		margin-bottom: .4rem;
		font-size: 1.4rem;
	}
}

.footer__head_text {
	font-weight: 400;
	line-height: 1;

	@include bp($point_2, min) {
		font-size: 1.4rem;
	}

	@include bp($point_3) {
		font-size: 1.2rem;
	}
}

.footer__main {
	background-color: $beige_1;

	@include bp($point_4, min) {
		padding: 4.8rem var(--gl-indent) 2.2rem;
	}

	@include bp($point_4 - 1) {
		padding: 3.2rem var(--gl-indent) 4.5rem;
	}
}

.footer__inner {
	max-width: $page_width;
	margin: 0 auto;
}

.footer__pagination {
	text-align: center;

	@include bp($point_4, min) {
		display: none;
	}

	@include bp($point_4 - 1) {
		padding-top: 1rem;
	};

	.swiper-pagination-bullet {
		width: .6rem;
		height: .6rem;
		margin: 0 .6rem!important;

		border: 1px solid $white;
		background-color: transparent;
		opacity: 1;
	}

	.swiper-pagination-bullet-active {
		background: $white;
	}
}

.footer_slider__button.slider_btn_1 {
	z-index: 100;
	position: absolute;
	top: 50%;
	margin-top: -2.2rem;

	@include bp($point_2, min) {
		display: none;
	}

	&.footer_slider__button--prev_mod {
		left: 2rem;
	}

	&.footer_slider__button--next_mod {
		right: 2rem;
	}
}

.footer__row_top {
	@include bp($point_4, min) {
		margin-bottom: 8.7rem;
		@include flex_block(row, nowrap, flex-start);
	}

	@include bp($point_4 - 1) {
		@include flex_block(column, wrap, flex-end, center, center);

		&:not(:last-child) {
			margin-bottom: 2.8rem;
		}
	}
}

.footer_logo {
	flex-shrink: 0;

	@include bp($point_4, min) {
		padding-right: 3rem;
	}

	@include bp($point_4 - 1) {
		&:not(:last-child) {
			margin-bottom: 2.4rem;
		}
	}
}

.footer_logo__link {
	color: $black;
	display: block;

	@include bp($point_4, min) {
		max-width: 28.1rem;
		height: 8.1rem;
	}

	@include bp($point_4 - 1) {
		max-width: 15.8rem;
		height: 3.2rem;
	}
}

.footer_logo__img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.footer_content {
	@include bp($point_4, min) {
		@include flex_block(row, nowrap, flex-start);

		max-width: 80.8rem;
		width: 100%;
		margin-left: auto;
	}

	@include bp($point_4 - 1) {
		@include flex_block(column, wrap, flex-start, center);
	}
}

.footer_nav {
	width: 38.5%;
	padding-top: 2.8rem;
	padding-right: 3rem;

	@include bp($point_4 - 1) {
		display: none;
	}
}

.footer_nav__item {
	&:not(:last-child) {
		margin-bottom: 1.9rem;
	}
}

.footer_nav__link {
	font-weight: 500;
	line-height: normal;
	color: inherit;
	transition: color .3s ease;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $gold_2;
	}
}

.footer_contact {

	@include bp($point_4, min) {
		width: 42.5%;
		padding-right: 1rem;
		padding-top: 2.8rem;
	}

	@include bp($point_4 - 1) {
		order: 2;
		text-align: center;

		&:not(:last-child) {
			padding-top: 1.8rem;
		}
	}
}

.footer_contact__head {
	margin-bottom: 2rem;

	@include bp($point_4 - 1) {
		display: none;
	}
}

.footer_contact__item {

	&:not(:last-child) {
		margin-bottom: 1.2rem;

		font-weight: 500;
		color: $gold_2;
	}

	@include bp($point_4, min) {
		font-size: 1.5rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.4rem;
		letter-spacing: -.02em;
		line-height: 1.42;
	}
}

.footer_contact__link {
	transition: color .3s ease;
	font-size: inherit;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: darken($gold_2, 10%);
	}

	@include bp($point_4 - 1) {
		display: none;
	}
}

.footer_social {
	@include bp($point_4, min) {
		padding-top: 1rem;
		width: 19%;
	}
}

.footer_social__list {
	@include flex_block(row, wrap, flex-end, center);

	@include bp($point_4, min) {
		margin: 0 -1.6rem -1.6rem 0;
	}

	@include bp($point_4 - 1) {
		margin: 0 -2rem -2rem 0;
	}
}

.footer_social__item {
	flex-shrink: 0;

	@include bp($point_4, min) {
		padding: 0 1.6rem 1.6rem 0;
	}

	@include bp($point_4 - 1) {
		padding: 0 2rem 2rem 0;
	}
}

.footer_social__link {
	display: block;
	border-radius: 50%;

	color: $gold_2;
	transition: color .3s ease;

	&:focus,
	&:hover {
		color: darken($gold_2, 10%);
	}

	@include bp($point_4, min) {
		width: 4.8rem;
		height: 4.8rem;
	}

	@include bp($point_4 - 1) {
		width: 4.4rem;
		height: 4.4rem;
	}
}

.footer__row_bottom {
	border-top: 1px solid $white;

	@include bp($point_4, min) {
		padding-top: 2rem;
	}

	@include bp($point_4 - 1) {
		padding-top: 3rem;
	}
}

.footer__col_wrap {
	font-size: 1.2rem;
	font-weight: 400;

	@include bp($point_4, min) {
		@include flex_block(row, nowrap, space-between);
		margin: 0 -2rem -2rem 0;
	}

	@include bp($point_4 - 1) {
		text-align: center;
	}
}

.footer__col {
	@include bp($point_4, min) {
		padding: 0 2rem 2rem 0;
	}

	@include bp($point_4 - 1) {
		&:not(:last-child) {
			margin-bottom: 1.8rem;
		}
	}
}

.footer__terms_list{
	@include bp($point_4, min) {
		@include flex_block(row, nowrap, flex-start, flex-start);
	}

	@include bp($point_4 - 1) {
		display: flex;
		flex-direction: column-reverse;
	}
}

.footer__terms_item {

	@include bp($point_4, min) {
		&:last-child {
			padding-left: 1.8rem;
		}
	}

	@include bp($point_4 - 1) {

		&:not(:first-child) {
			margin-bottom: 1rem;
		}
	}
}

.footer__terms_link {
	position: relative;
	letter-spacing: -0.01em;

	transition: color .3s ease;
	color: inherit;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $gold_2;
	}

	@include bp($point_4, min) {
		.footer__terms_item:last-child & {
			&:before {
				position: absolute;
				left: -.8rem;
				top: 50%;
				transform: translateY(-50%);

				display: block;
				width: 1px;
				height: .7em;
				background-color: $black;
				content: "";
			}
		}
	}
}

.footer__copyright {

}
