$icon_font_name: "iconfont";
@font-face {
	font-family: $icon_font_name;
	src: url('../fonts/icons/iconfont.eot');
	src: url('../fonts/icons/iconfont.eot?#iefix') format('eot'),
	url('../fonts/icons/iconfont.woff') format('woff'),
	url('../fonts/icons/iconfont.ttf') format('truetype'),
	url('../fonts/icons/iconfont.svg#iconfont') format('svg');
}

@mixin icon-check {
	&:before {
		font-family: $icon_font_name;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: 400;
		// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
		text-decoration: none;
		text-transform: none;
	}
}

@mixin last_selector {
	&:before {
		font-family: $icon_font_name;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: 400;
		// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
		text-decoration: none;
		text-transform: none;
	}
}

@mixin icons_before_after {
	&:before,
	&:after {
		font-family: $icon_font_name;
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin icons_after {
	&:after {
		font-family: $icon_font_name;
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin icon-check {
	&:before {
		content: '\E001';
	}
}

