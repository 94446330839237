@import "map";

.get_in_touch {}

.get_in_touch__row {
	@include bp($point_2, min) {
		display: flex;
		flex-wrap: wrap;

		margin: 0 -3.5rem -3.5rem 0;
	}
}

.get_in_touch__column {
	@include bp($point_2, min) {
		padding: 0 3.5rem 3.5rem 0;

		&:nth-child(2n+1){
			width: 64%;
		}
		&:nth-child(2n+2){
			width: 36%;
		}	
	}
	@include bp($point_3) {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 4rem;
		}
	}
}

.get_in_touch__title {
	letter-spacing: -.018em;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	@include h2;
}

.get_in_touch__text {
	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 4.2rem;
		}
		@include bp($point_3) {
			margin-bottom: 3rem;
		}
	}

	@include text;

	@include bp($point_2, min) {
		line-height: 1.5;
	}
	@include bp($point_3) {
		line-height: 1.44;
	}
}

.get_in_touch__form {
	.divider {
		&:not(:last-child) {
			@include bp($point_2, min) {
				margin-bottom: 3.8rem;
			}
			@include bp($point_3) {
				margin-bottom: 2.8rem;
			}
		}
	}

	@include bp($point_2, min) {
		max-width: 74rem;
	}
}

.get_in_touch__wrap {
	&:not(:last-child) {
		margin-bottom: 2.8rem;
	}
}

.get_in_touch__body {
	display: flex;
	flex-wrap: wrap;

	margin: 0 -2.8rem -2.1rem 0;
}

.get_in_touch__item {
	padding: 0 2.8rem 2.1rem 0;

	@include bp($point_4, min) {
		&.get_in_touch__item--full_mod {
			width: 100%;
		}
		&.get_in_touch__item--size_mod {
			width: 100%;
			max-width: 63.2rem;
		}
		&:not(.get_in_touch__item--full_mod,
			.get_in_touch__item--size_mod) {
			width: 50%;
		}		
	}
	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.get_in_touch__checkbox {
	&:not(:last-child) {
		margin-bottom: 2.2rem;
	}
}

.get_in_touch_captcha {
	width: 27.4rem;
	height: 8.2rem;

	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}
}

.get_in_touch_captcha__pic {
	display: block;
	width: 100%;
	height: 100%;
}

.get_in_touch_captcha__img {
	@include contain_img
}

// contacts
.get_in_touch_contacts {
	@include bp($point_2, min) {
		padding-left: 2.6rem;
		padding-top: 1.8rem;
	}
}

.get_in_touch_contacts__item {
	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 3.2rem;
		}
		@include bp($point_3) {
			margin-bottom: 2rem;
		}
	}
}

.get_in_touch__button {
	@include bp($point_6 - 1) {
		width: 100%;
	}
}
