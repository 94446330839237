@import "family";
@import "reset";
@import "fonts";
@import "print";

//------------------------------------------------------------base styles
:root {
	--full-screen: calc(var(--vh, 1vh) * 100);
	--height-footer: 10rem;
	--gl-indent: 3rem;

	@include bp($point_2, min) {
		--height-header: 12.8rem;
	}

	@include bp($point_3) {
		--height-header: 6.4rem;
	}
}

html {
	height: 100%;


	@include bp($point_9, min) {
		font: 400 10px / 1.33 $font_1;
	}

	@include bp($point_9 - 1, $point_1) {
		font: 400 calc(100vw / 1600 * 10) / 1.33 $font_1;
	}
	@include bp($point_1 - 1, $point_2) {
		font: 400 9px / 1.33 $font_1;
	}

	@include bp($point_3, 390px) {
		font: 400 10px / 1.33 $font_1;
	}

	@include bp(389px) {
		font: 400 calc(100vw / 390 * 10) / 1.33 $font_1;
	}

	//&.html--open_menu_state {
	//	//overflow-y: scroll;
	//}
}

body {
	position: relative;

	display: flex;
	//height: 100%;

	font-size: $font_size_base;
	color: $color_text_base;

	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background: $body_bg;

	&.body--popup_open {
		// position: fixed;
		// left: 0;
		// top: 0;

		// width: 100%;
		overflow: hidden;
	}

	@supports (display: grid) {
		display: block;
	}

	&.body--menu_open {
		@include bp($point_2) {
			overflow: hidden;
		}
	}
	&.body--open_menu_state {
		@include bp($point_3) {
			overflow: hidden;
		}
	}
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: $white;

	opacity: 1;
	transition: opacity 0.3s;

	z-index: 1000;

	.body--loaded & {
		pointer-events: none;
		opacity: 0;
	}
}

.wrapper {
	position: relative;

	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: auto !important;

	overflow-x: hidden;
}

.base {
	width: 100%;
	position: relative;
	padding: var(--height-header) 0 0;

	flex: 1 0 auto;
}

a {
	text-decoration: none;
	color: $color_link_base;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.divider {
	width: 100%;

	height: 1px;

	// margin: 5.8rem auto 4.6rem;
	margin: 0 auto;

	&::after {
		display: block;

		content: "";

		width: 100%;
		height: 100%;

		background-color: $beige_2;
	}

	.section & {
		&:not(:last-child) {
			@include bp($point_2, min) {
				margin-bottom: 2.4rem;
			}

			@include bp($point_3) {
				margin-bottom: 1.8rem;
			}
		}
	}

	&:not(.section &) {
		max-width: calc($page_width + var(--gl-indent) * 2);
		padding: 0 var(--gl-indent);
	}

	.section.memberships_tabs & {
		@include bp($point_2, min) {
			margin-bottom: 4rem;
		}
		@include bp($point_3) {
			display: none;
		}
	}
}

.fancybox__html5video {
	&:focus {
		outline: none !important;
	}
}

.stamped-badge-caption {
	flex-shrink: 0;
	position: relative;
	border-bottom: 1px solid rgba($gold_1, 0.8);

	letter-spacing: -0.016em;
	line-height: 1.38;
	color: $gold_1;

	transition: color 0.3s ease, border-bottom-color 0.3s ease;

	&:hover,
	&:focus {
		color: $gold_2;
		text-decoration: none;
		border-color: transparent;
	}

	@include bp($point_2, min) {
		margin-left: 0.7rem;
		font-size: 1.4rem;
	}

	@include bp($point_3) {
		margin-left: 0.4rem;
		font-size: 1rem;
	}

	.prod_spotlight & {
		@include bp($point_3) {
			margin-left: 0.4rem;
			font-size: 1.2rem;
			line-height: 1;
		}
	}
}

input[type="submit"] {
	appearance: none;
	-webkit-appearance: none;
}

//------------------------------------------------------------base styles###
