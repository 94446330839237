.header_item__link {
	position: relative;

	font-size: 1.5rem;
	color: $black;
	transition: color 0.3s ease;

	overflow: hidden;

	&:not(:last-child) {
		margin-right: 0.7rem;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: -100%;

		content: "";

		width: 100%;
		height: 1px;

		background-color: $gold_2;
		transition: left 0.3s ease;

		.header_item__item:hover &,
		.header_item__item:focus & {
			left: 0;
		}
	}

	.header_item__item:hover &,
	.header_item__item:focus & {
		color: $gold_2;
		text-decoration: none;
	}
}

.header_item {
	position: relative;

	cursor: pointer;
}

.header_item__trigger {
	@include flex_block(row, nowrap, flex-start, center);

	cursor: pointer;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

.header_item__icon {
	position: relative;

	width: 1.6rem;
	height: 1.8rem;

	color: $gold_2;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: 0.8rem;
	}
}

.header_item__title {
	position: relative;
	display: block;

	font-size: 1.5rem;
	color: $black;
	transition: color 0.3s ease;

	overflow: hidden;

	&:not(:last-child) {
		margin-right: 0.7rem;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: -100%;

		content: "";

		width: 100%;
		height: 1px;

		background-color: $gold_2;
		transition: left 0.3s ease;

		.header_item:hover &,
		.header_item:hover & {
			left: 0;
		}
	}

	&.header_item__title--color_mod {
		font-size: 1.3rem;
		font-weight: 500;
		letter-spacing: 0.1em;
		color: $gold_2;
		text-transform: uppercase;

		&:not(:last-child) {
			margin-right: 4px;
		}
	}

	.header_item:hover &,
	.header_item:hover & {
		text-decoration: none;
	}
}

.header_item__count {
	position: absolute;
	top: -6px;
	right: -7px;

	width: 1.5rem;
	height: 1.4rem;

	@include flex_block(row, nowrap, center, center);

	font-size: 0.7rem;
	font-weight: 700;
	letter-spacing: 0.01em;
	color: $white;
	background-color: $gold_2;
	border-radius: 50%;
}

.header_item__arrow {
	width: 1rem;
	height: 1rem;

	color: $gold_2;

	transition: transform 0.3s ease;

	.header_item:hover &,
	.header_item:hover & {
		transform: rotate(-180deg);
	}
}

.header_item__lang {
	width: 2rem;
	height: 2rem;

	border-radius: 50%;
	border: 1px solid $gold_2;
	overflow: hidden;

	&:not(:last-child) {
		margin-right: 0.5rem;
	}
}

.header_item__lang_img {
	@include cover_img;
}

.header_item__dropdown {
	position: absolute;
	top: 100%;
	right: 0;
	z-index: 100;

	height: auto;
	padding: 1rem;

	overflow-y: auto;

	opacity: 0;
	visibility: hidden;

	transition: opacity 0.3s ease;

	.header_item:hover &,
	.header_item:focus & {
		opacity: 1;
		visibility: visible;
	}

	@include bp($point_2, min) {
		width: 24.8rem;
	}
	@include bp($point_3) {
		width: auto;
	}
}

.header_item__dropdown_body {
	padding: 1rem;
	background-color: $white;

	box-shadow: 2px 2px 1.2rem rgba(108, 59, 18, 0.25);
	transition: opacity 0.3s ease;
}

.header_item__dropdown_item {
	position: relative;
	padding: 0.6rem 3.2rem;

	transition: background 0.3s ease;

	&:hover,
	&:focus {
		background-color: $beige_1;
	}

	.header_item__dropdown--style_mod & {
		padding: 0.6rem 1.8rem;
	}

	&.header_item__dropdown_item--active_mod {
		&::before {
			content: "";
			position: absolute;
			left: 1rem;
			top: 50%;
			display: block;
			width: 0.8rem;
			height: 1.4rem;
			border: 2px solid $gold_2;
			border-top: 0;
			border-left: 0;
			transform: rotate(45deg) translate(-58%, -58%);
		}
	}
}

.header_item__dropdown_link {
	@include text;

	line-height: 1.5;
	color: $black;

	transition: color 0.3s ease;

	.header_item__dropdown_item:hover &,
	.header_item__dropdown_item:focus & {
		color: $gold_2;
		text-decoration: none;
	}
}
