.search_hero {
	color: $white;

	.section_in {
		position: relative;
	}
}

.search_hero_bg {
	z-index: 0;

	display: block;
	width: 100%;
	height: 100%;

	@include stretch;
}

.search_hero_bg__img {
	@include cover_img;
}

.search_hero__title {
	letter-spacing: -0.016em;

	&:not(:last-child) {
		margin-bottom: 1.8rem;
	}

	@include h1;
}

.search_hero__row {
	@include bp($point_4, min) {
		display: flex;
	}
}

.search_hero__form {
	position: relative;

	@include bp($point_4, min) {
		min-width: 66%;

		&:not(:last-child) {
			margin-right: 2rem;
		}
	}
	@include bp($point_4 - 1) {
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 1.8rem;
		}
	}
}

.search_hero__button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	z-index: 3;

	margin: 0 1rem 0 0;

	color: $gold_2;
	cursor: pointer;

	@include bp($point_2, min) {
		width: 2.4rem;
		height: 2.5rem;		
	}
	@include bp($point_3) {
		width: 2rem;
		height: 2.1rem;
	}
}

.search_hero__info {
	line-height: 1.5;

	@include text;

	@include bp($point_4, min) {
		margin-left: auto;
		padding-top: 0.6rem;
	}
}
