//@import "hero-rich-text";

.hero_slider {
	padding: 0;
}

.hero_slider__body {
}

.hero_slider__controls_wrap {
	z-index: 100;
	position: absolute;
	pointer-events: none;

	padding: 0 var(--gl-indent);
	width: 100%;

	@include bp($point_2, min) {
		bottom: 9.6rem;
	}

	@include bp($point_3) {
		bottom: 4.6rem;
	}

	.swiper-pagination-fraction {
		@include bp($point_2, min) {
			width: auto;
		}
	}
}

.slider_controls.hero_slider__controls {
	@include bp($point_2, min) {
		justify-content: flex-end;
	}
}

.hero_slider__button {
	pointer-events: initial;

	&.hero_slider__button--prev_mod {

		@include bp($point_2, min) {
			order: 2;
			margin-right: 1.2rem;
		}

		@include bp($point_3) {
			margin-right: .6rem;
		}
	}

	&.hero_slider__button--next_mod {

		@include bp($point_2, min) {
			order: 3;
		}
	}
}

.hero_slider__pagination.slider_controls__pagination {
	color: $white;

	@include bp($point_2, min) {
		margin-right: 2.4rem;
	}

	@include bp($point_3) {
		margin-left: 2.4rem;
	}
}

.hero_slider__slider_item {
	display: flex;

	@include bp($point_2, min) {
		min-height: 63.4rem;
		padding: 3rem var(--gl-indent) 9.6rem;
	}

	@include bp($point_3) {
		min-height: 57.6rem;
		padding: 3rem var(--gl-indent) 12.4rem;
	}
}

.hero_slider__bg {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.hero_slider__bg_img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.hero_slider__row {
	height: 100%;

	@include bp($point_4, min) {
		@include flex_block(row, wrap, flex-start, flex-end, flex-end);
	}

	@include bp($point_4 - 1) {
		@include flex_block(column, wrap, flex-end, flex-end, flex-end);
	}
}

.hero_slider__col {
	@include bp($point_4, min) {
		width: 52%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		order: 2;
	}
}

.hero_slider__col_video {
	position: relative;

	@include bp($point_4, min) {
		height: 100%;
		width: 48%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		flex: auto;
	}
}

.hero_slider__icon {
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;

	color: $white;
	cursor: pointer;

	@include bp($point_4, min) {
		margin-top: -4.6rem;
		margin-left: -2.8rem;

		width: 12rem;
		height: 12rem;
	}

	@include bp($point_4 - 1) {
		margin-top: -4rem;
		margin-left: -4rem;

		width: 8rem;
		height: 8rem;
	}
}
