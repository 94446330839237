.mbs_slider {
	// null swiper styles
	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		opacity: 1;
		background: transparent;
		margin: 0;
	}

	@include bp($point_2, min) {
		padding-top: 10.4rem;
	}

}
// section head
.mbs_slider__head {
	max-width: 109rem;

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 5rem;
		}
		@include bp($point_3) {
			margin-bottom: 3rem;
		}
	}
}

.mbs_slider__title {
	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 1.7rem;
		}
		@include bp($point_3) {
			margin-bottom: .7rem;
		}
	}

	@include h2;

	@include bp($point_2, min) {
		letter-spacing: -.018em;
	}
	@include bp($point_3) {
		letter-spacing: -.024em;
	}
}

.mbs_slider__text {
	@include bp($point_2, min) {
		font-size: 2rem;
		line-height: 1.6;
	}
	@include bp($point_3) {
		font-size: 1.6rem;
		line-height: 1.5;
		letter-spacing: -.0342em;
	}
}

// navigation
.mbs_slider_scores {
	display: flex;
	align-items: flex-end;
	width: 100%;

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 2rem;
		}
		@include bp($point_3) {
			margin-bottom: .5rem;
		}
	}

	@include bp($point_2, min) {
		padding: 0 3px;
	}
	@include bp($point_3) {
		flex-wrap: wrap;
	}
}

.mbs_slider_scores__wrap {
	width: 100%;

	@include bp($point_2, min) {
		padding: 0 1rem;
	}
	@include bp($point_3) {
		padding: 4px 1.5rem 0 1rem;
	}
}

.mbs_slider_scores__in {
	max-width: 76rem;
	width: 100%;
	margin: 0 auto;

	overflow-y: hidden;
	overflow-x: auto;
}

.mbs_slider_scores__pagination {
	display: flex;
	justify-content: space-between;

	width: 100%;

	.swiper-pagination-bullet {
		text-align: center;
		letter-spacing: -0.02em;
		color: $gray_5;

		cursor: pointer;

		&.swiper-pagination-bullet-active,
		&.swiper-pagination-bullet-active + span {
			color: $black;
			font-weight: 500;
		}

		&:hover,
		&:focus{
			color: $black;
		}

		@include transition_color;

		@include bp($point_2, min) {
			min-width: 5.6rem;
			height: 5.2rem;

			font-size: 4.2rem;
		}
		@include bp($point_3) {
			min-width: 4.6rem;
			height: 3.4rem;

			font-size: 2.4rem;
		}
	}
}

.mbs_slider_scores__arrow {
	display: flex;
	align-items: center;
	flex-shrink: 0;

	font-family: $font_1;
	color: $gold_2;
	font-weight: 500;
	line-height: 1.5;
	text-transform: uppercase;

	cursor: pointer;
	
	&:hover,
	&:focus{
		opacity: .8;
	}

	&.mbs_slider_scores__arrow--next_mod {
		text-align: right;

		@include bp($point_2, min) {
			order: 2;
		}
		@include bp($point_3) {
			margin-left: auto;
		}
	}

	&.swiper-button-disabled {
		pointer-events: none;
	}

	@include transition_opacity;

	@include bp($point_2, min) {
		font-size: 1.6rem;
		letter-spacing: .1em;
	}
	@include bp($point_3) {
		max-width: 50%;

		font-size: 1.1rem;
		letter-spacing: 0.8px;
	}
}

.mbs_slider_scores__icon {
	$indent: .8rem;
	
	display: flex;
	flex-shrink: 0;
	
	width: .8rem;
	height: 1.4rem;

	.mbs_slider_scores__arrow--prev_mod & {
		transform: rotate(-180deg);
		order: -1;
		margin-right: $indent;
	}
	.mbs_slider_scores__arrow--next_mod & {
		margin-left: $indent;
	}

	@include bp($point_3) {
		display: none;
	}
}

.mbs_slider_scrollbar {
	background: $gradient;

	width: 100%;

	@include bp($point_2, min) {
		height: 2.8rem;
		padding: .7rem;
	}
	@include bp($point_3) {
		height: 1.8rem;
		padding: .5rem 2.2rem;
	}
}

.mbs_slider_scrollbar__in {
	max-width: 74.8rem;
	height: 100%;
	margin: 0 auto;

	.swiper-scrollbar-drag {
		cursor: grab;

		border-radius: .7rem;
		background-color: $white;
	
		@include bp($point_2, min) {
			height: 1.4rem;
		}
		@include bp($point_3) {
			height: .8rem;
		}
	}
}

// slider
.mbs_slider_item {
	background-color: $beige_1;

	@include bp($point_3) {
		padding: 2rem 2.4rem 4.1rem;
	}
}

.mbs_slider_item__row {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
}

.mbs_slider_item__column {
	@include bp($point_2, min) {
		&:nth-child(2n+1){
			width: 44.2%;
		}
		&:nth-child(2n+2){
			width: 55.8%;
		}
	}
	@include bp($point_3) {
		width: 100%;
		height: 100%;

		&:not(:last-child) {
			margin-bottom: 1.2rem;
		}
	}
}

.mbs_slider_item_image {
	position: relative;

	width: 100%;
	height: 100%;

	@include bp($point_2, min) {
		min-height: 54.1rem;
	}
	@include bp($point_3) {
		min-height: 25.4rem;
	}
}

.mbs_slider_item_image__wrap {
	display: block;
	width: 100%;
	height: 100%;

	@include stretch;
}

.mbs_slider_item_image__in {
	@include contain_img;

	@include bp($point_2, min) {
		object-position: right;
	}
	@include bp($point_3) {
		object-position: calc(50% - 4px);
	}
}

.mbs_slider_item__wrap {
	@include bp($point_2, min) {
		padding: 4.5rem 5rem 3.7rem 2rem;
	}
}

.mbs_slider_item__content {
	max-width: 53.8rem;
	margin: 0 auto;
}

.mbs_slider_item__head {
	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 3.2rem;
		}
		@include bp($point_3) {
			margin-bottom: 1.5rem;
		}
	}

	@include bp($point_2, min) {
		&.mbs_slider_item__head--mobile_mod {
			display: none;
		}
	}
	@include bp($point_3) {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;

		&.mbs_slider_item__head--desktop_mod {
			display: none;
		}
	}
}

.mbs_slider_item__arrow {
	flex-shrink: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 4.4rem;
	height: 4.4rem;
	margin-bottom: 8px;

	color: $gold_2;
	cursor: pointer;

	&.mbs_slider_item__arrow--prev_mod {
		margin-left: -1.5rem;
	}
	&.mbs_slider_item__arrow--next_mod {
		transform: rotate(-180deg);
		margin-right: -1.5rem;
	}

	&:hover,
	&:focus{
		opacity: .8;
	}

	@include transition_opacity;
}

.mbs_slider_item__icon {
	width: 1.4rem;
	height: 2.8rem;
}	

.mbs_slider_item__info {
	text-align: center;
}

.mbs_slider_item__score {
	font-weight: 500;
	letter-spacing: -.016em;

	@include bp($point_2, min) {
		font-size: 7.4rem;
		line-height: 1.11;
	}
	@include bp($point_3) {
		font-size: 4.8rem;
		line-height: 1.03;
	}
}

.mbs_slider_item__label {
	color: $gold_2;
	font-weight: 500;
	letter-spacing: .11em;
	line-height: 1.33;
	text-transform: uppercase;

	@include bp($point_2, min) {
		font-size: 1.8rem;
	}
	@include bp($point_3) {
		font-size: 1.3rem;
	}
}

.mbs_slider_item__divider {
	height: 2px;
	width: 100%;

	background-color: $white;

	&:not(:last-child) {
		margin-bottom: 2.6rem;
	}

	@include bp($point_3) {
		display: none;
	}
}

.mbs_slider_item__text {
	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 1.4rem;
		}
		@include bp($point_3) {
			margin-bottom: .8rem;
		}
	}

	@include bp($point_2, min) {
		font-size: 2.2rem;
		line-height: 1.45;
	}
	@include bp($point_3) {
		font-size: 1.6rem;
		line-height: 1.5;
		letter-spacing: -.037em;
	}
}

.mbs_slider_item__subtext {
	@include text;

	@include bp($point_2, min) {
		line-height: 1.5;
	}
	@include bp($point_3) {
		line-height: 1.43;
		letter-spacing: -.017em;
	}
}
