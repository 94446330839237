@mixin transition_all {
	transition: all .3s ease;
	will-change: transform;
}

@mixin transition_opacity {
	transition: opacity .3s ease;
	will-change: opacity;
}

@mixin transition_background {
	transition: background .3s ease;
	will-change: background;
}

@mixin transition_color {
	transition: color .3s ease;
	will-change: color;
}

@mixin transition_color_background {
	transition: color .3s ease, background .3s ease;
	will-change: color, background;
}

@mixin transition_transform {
	transition: transform .3s ease;
	will-change: transform;
}

@mixin tranisition_fill {
	transition: fill .3s ease;
	will-change: fill;
}
