.header_bottom {
	position: relative;
	z-index: 10;

	padding: 1.6rem var(--gl-indent) 0;
	background-color: $beige_1;

	@include bp($point_3) {
		display: none;
	}
}

.header_bottom__wrap {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
