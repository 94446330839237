.total_details {
	&:not(:last-child) {
		margin-bottom: 4.1rem;
	}
}

.total_details__title {
	color: $gray_2;
	font-size: 1.6rem;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: .094em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 1.3rem;
	}
}

.total_details__body {
	background-color: $gray_3;

	&:not(:last-child) {
		margin-bottom: 3.3rem;
	}

	@include bp($point_2, min) {
		padding: 2.8rem 4rem;
	}
	@include bp($point_3) {
		padding: 2.5rem 2rem;
	}
}

.total_details__items {
	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}
}

.total_details__wrap {
	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}
}

.total_details__row {
	display: flex;
	align-items: stretch;
	margin: 0 -1.6rem -1.6rem 0;
}

.total_details__column {
	display: flex;
	flex-direction: column;
	
	padding: 0 1.6rem 1.6rem 0;

	&:nth-child(2n+2){
		margin-left: auto;
		text-align: right;
	}
}

.total_details__text {
	color: $black;
	font-size: 1.6rem;
	font-weight: 500;

	&:not(:last-child) {
		margin-bottom: 2px;
	}
}

.total_details__label {
	color: $black;
	font-size: 1.2rem;
}

.total_details__price {
	color: $black;
	font-size: 1.6rem;
	font-weight: 500;
}

.total_details__summary {
	border-top: 2px solid $white;
	padding: 2.1rem 0 .7rem;
}

.total_details__total_label {
	font-size: 1.6rem;
	font-weight: 500;
	letter-spacing: .094em;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: .7rem;
	}

	&.checkout_details_total__label--accent_mod {
		color: $black;
	}
	&:not(.checkout_details_total__label--accent_mod) {
		color: $gray_2;
	}
}

.total_details__total_price {
	color: $black;
	font-size: 1.6rem;
	font-weight: 500;

	&:not(:last-child) {
		margin-bottom: .7rem;
	}
}

.total_details__total_text {
	color: $gray_6;
	font-size: 1.3rem;
	letter-spacing: -.015em;
}

.total_details__date {
	margin-top: auto;
	
	color: $gray_6;
	font-size: 1.3rem;
}

.total_details__btn {
	& .btn_1 {
		width: 100%;
	}
}