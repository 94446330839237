@mixin circle_ico_style {
	$w: 24px;
	position: absolute;
	width: $w !important;
	height: $w;
	top: 50%;
	margin: math(-$w, 2) 0 0;
	text-align: center;
	line-height: $w;
	border-radius: 50%;
}

@mixin circle_ico_style_small {
	$w: 20px;
	position: absolute;
	width: $w !important;
	height: $w;
	top: 50%;
	margin: math(-$w, 2) 0 0;
	text-align: center;
	line-height: $w;
	border-radius: 50%;
}

@mixin translate3d {
	transform: translateZ(0);
	transform-style: preserve-3d;
	backface-visibility: hidden;
}

@mixin vert_centr {
	top: 50%;
	transform: translate(0, -50%);
}

@mixin hor_centr {
	left: 50%;
	transform: translate(-50%, 0);
}

@mixin hor_vert_centr {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin stretch {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

@mixin hover_show {
	opacity: 0;
	transition: opacity .3s ease;
}

@mixin hover_show_parent {
	&:hover,
	&:focus {
		@include hover_show {
			opacity: 1;
		}
	}
}

@mixin scroll_rediz_1 {
	&::-webkit-scrollbar {
		width: 6px;
		height: 12px;
		background: transparent;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		width: 6px;
		height: 12px;
		border-radius: 12px;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}
}

@mixin text-overflow {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin z-index_fix_vertical {
	$index: 20;
	$index2: 21;
	@while $index > 0 {

		&:nth-child(#{$index}) {
			z-index: 10 + ($index2 - $index);
		}

		$index: $index - 1;
	}
}

@mixin rounded_i_w {
	display: block;
	position: relative;
	border-radius: 50%;
	overflow: hidden;
}

@mixin rounded_i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	min-height: 100%;
}

@mixin responsive_animation_wrap{
	position: absolute;
	width: 10%;
	height: 10vh;
	top: 50%;
	left: 50%;
	margin: -5vh 0 0 (-5%);
	transform: translateZ(0);
	will-change: transform;
}