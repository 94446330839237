.hero_rich_text {
	color: $white;
}

.hero_rich_text__title {
	@include h1;
	letter-spacing: -.016em;

	@include bp($point_2, min) {
		margin-bottom: 2.4rem;
		line-height: 1;
	}

	@include bp($point_3) {
		margin-bottom: 1.2rem;
		line-height: 1.05;
	}
}

.hero_rich_text__description {
	max-width: 60rem;
	font-weight: 400;
	letter-spacing: normal;

	p:not(:last-child) {
		margin-bottom: 1rem;
	}

	&:not(:last-child) {
		@include bp($point_2, min) {
			margin-bottom: 3.4rem;
		}

		@include bp($point_3) {
			margin-bottom: 2.4rem;
		}
	}

	@include bp($point_2, min) {
		font-size: 2.2rem;
		line-height: 1.45;
	}

	@include bp($point_3) {
		font-size: 1.6rem;
		line-height: 1.5;
	}
}

.hero_rich_text__button_list {
	@include flex_block(row, wrap, flex-start, center);
	@include bp($point_2, min) {
		margin: 0 -2.4rem -2.4rem 0;
	}

	@include bp($point_3) {
		margin: 0 -1.8rem -1.8rem 0;
	}
}

.hero_rich_text__button_item {
	@include bp($point_2, min) {
		padding: 0 2.4rem 2.4rem 0;
	}

	@include bp($point_3) {
		padding: 0 1.8rem 1.8rem 0;
	}
}



