.form_radio__block {
	display: block;

	.pet_food__2_list & {
		cursor: pointer;

		.form_radio__label_2 {
			position: absolute;
			right: 5.5rem;
		}
	}
}

.form_radio__element {
	display: none;
}

.form_radio__label {
	@include form_el_radio;

	.sort_by_mobile__item & {
		cursor: pointer;
		transition: color .3s ease;

		&:focus,
		&:hover {
			color: $gold_2;
		}
	}
}
